import gql from "graphql-tag";

export const UPDATE_EMBEDDEDAPP = gql`
    mutation UpdateEmbeddedApp($updateEmbeddedAppId: String!, $input: UpdateEmbeddedAppInput!, $tenantId: String!) {
        updateEmbeddedApp(id: $updateEmbeddedAppId, input: $input, tenantId: $tenantId) {
            description
            displayName
            name
            url
            tenant {
                displayName
            }
        }
    }
`;
import React, { useState } from "react";
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import CenteredProgress from '../components/CenteredProgress';
import { Grid, Box, Typography, Modal, Backdrop, Container, Button } from "@mui/material";
import '../Form.css'
import Select, { components } from "react-select";
import { LIST_USERS_TENANT } from '../graphql/queryListUsersOfTenant';
import { ADD_USERS_TO_TEAM } from '../graphql/mutationAddUserstoTeam';
import { ADD_USERS_PERMISSION, UPDATE_REPORTS_PERMISSION } from '../graphql/mutationAddUserPermissions';
import { UPDATE_TEAM } from '../graphql/mutationUpdateTeam';
import { REMOVE_USERS_FROM_TEAM } from '../graphql/mutationRemoveUserFromTeam';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: '#fff',
    boxShadow: 24,
    padding: '0 !important',
    margin: '0 0 15px 0 !important'
};
const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex ",
        padding: "0 0 15px 0 !important",
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} />
            {children}
        </components.Option>
    );
};

export default function UpdateTeam({ tenant, teamlist, updateteams, file, showModal, embeddedApps, reportList, onClose }) {

    const [formError, setFormError] = useState();
    const [selectedusers, setSelectedUsers] = useState([]);
    const [selectedapps, setSelectedApps] = useState([]);
    const [selectedreports, setSelectedReports] = useState([]);
    const [updateTeam] = useMutation(UPDATE_TEAM);
    const [addTeamUsers] = useMutation(ADD_USERS_TO_TEAM);
    const [removeTeamUsers] = useMutation(REMOVE_USERS_FROM_TEAM);
    const [addTeamPermission] = useMutation(ADD_USERS_PERMISSION);
    const [appschanged, SetAppsChanged] = useState(false);
    const [userschanged, SetUsersChanged] = useState(false);
    const [reportschanged, SetReportsChanged] = useState(false);
    const [addTeamReportPermission] = useMutation(UPDATE_REPORTS_PERMISSION);
    const handleClose = () => {
        onClose();
    };


    const oldmemebers = _.get(file, "members", []);
    const oldpermissions = _.get(file, "permissions", []);

    let newteamitem = []
    const {
        loading: loadingListUsers,
        error: errorListUsers,
        data: dataListUsers,
    } = useQuery(LIST_USERS_TENANT, {
        variables: {
            input: {
                _id: tenant._id,
            },
        },
    });
    const usersData = dataListUsers ? dataListUsers.findOneTenant : '';
    const users = usersData ? usersData.tenantUsers.map((c) => {
        return {
            id: _.get(c, 'user._id'),
            firstName: _.get(c, 'user.firstName'),
            lastName: _.get(c, 'user.lastName'),
            email: _.get(c, 'user.email'),
        };
    }) : '';
    const userList = users ? users.map((u) => {
        return {
            value: u.id, label: u.firstName + " " + u.lastName
        }

    }) : '';

    const embeddedAppList = embeddedApps ? embeddedApps.map((a) => {
        return {
            value: a._id, label: a.name
        }
    }) : '';

    const ReportsList = reportList ? reportList.map((a) => {
        return {
            value: a._id, label: a.name
        }
    }) : '';


    let oldmemberslist = []
    if (oldmemebers) {
        oldmemebers.forEach(element => {
            const index = userList.findIndex(object => {
                return object.value === element._id;
            });
            oldmemberslist.push(userList[index])
        });
    }

    let oldappslist = []
    if (oldpermissions.filter(item => item.includes('apps'))) {
        const permissionlist = oldpermissions.filter(item => item.includes('apps'));
        const newlist = permissionlist.map(e => e.split('apps:')[1].split('/')[0]);
        newlist.forEach(element => {
            const index = embeddedAppList.findIndex(object => {
                return object.value === element;
            });
            oldappslist.push(embeddedAppList[index])
        });
    }
    let oldreportsList = []
    if (oldpermissions.filter(item => item.includes('reports'))) {
        const permissionlist = oldpermissions.filter(item => item.includes('reports'));
        const newlist = permissionlist.map(e => e.split('reports:')[1].split('/')[0]);
        newlist.forEach(element => {
            const index = ReportsList.findIndex(object => {
                return object.value === element;
            });
            oldreportsList.push(ReportsList[index])
        });
    }

    var appstoremove = oldappslist.map((app) => { return app.value })
    var reportstoremove = oldreportsList.map((report) => { return report.value })
    var userstoremove = oldmemberslist.map((user) => { return user.value })

    appstoremove = selectedapps.length > 0 ? appstoremove.filter(id => !selectedapps.includes(id)) : appstoremove;
    reportstoremove = selectedreports.length > 0 ? reportstoremove.filter(id => !selectedreports.includes(id)) : reportstoremove;
    userstoremove = selectedusers.length > 0 ? userstoremove.filter(id => !selectedusers.includes(id)) : userstoremove;


    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        //Name Validation
        if (!value) {
            setFormError("**Team name cannot be empty")
        }
        else if (typeof value !== "undefined") {
            if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
                setFormError("**Only letters and numbers are accepted")
            } else {
                setFormError(" ")
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            const updateteam = await updateTeam({
                variables: {
                    input: {
                        name: event.target[0].value,
                        displayName: event.target[0].value.replace(/\s/g, "_"),
                        abilities: {
                            canDownloadFromFileDatasets: event.target[2].checked,
                            canUploadToFileDatasets: event.target[1].checked
                        }
                    },
                    teamId: file.id,
                    tenantId: tenant._id
                }
            })
            const newTeam = _.get(updateteam, 'data.updateTeam');
            let newpermissions;
            if (selectedapps.length > 0) {
                if (oldappslist.length > 0 && appstoremove.length > 0) {
                    await addTeamPermission({
                        variables: {
                            embeddedAppId: appstoremove,
                            input: {
                                canRead: false
                            },
                            teamId: newTeam._id,
                            tenantId: tenant._id
                        },
                    })
                }
                const teampermission = await addTeamPermission({
                    variables: {
                        embeddedAppId: selectedapps,
                        input: {
                            canRead: true
                        },
                        teamId: newTeam._id,
                        tenantId: tenant._id
                    },
                })
                newpermissions = _.get(teampermission, 'data.updateManyEmbeddedAppPermissions');


            }

            if (selectedapps.length === 0) {
                if (oldappslist.length > 0 && appschanged) {
                    const teampermission = await addTeamPermission({
                        variables: {
                            embeddedAppId: appstoremove,
                            input: {
                                canRead: false
                            },
                            teamId: newTeam._id,
                            tenantId: tenant._id
                        },

                    })
                    newpermissions = _.get(teampermission, 'data.updateManyEmbeddedAppPermissions');
                }
                if (oldappslist.length > 0 && !appschanged) {
                    const teampermission = await addTeamPermission({
                        variables: {
                            embeddedAppId: appstoremove,
                            input: {
                                canRead: true
                            },
                            teamId: newTeam._id,
                            tenantId: tenant._id
                        },

                    })
                    newpermissions = _.get(teampermission, 'data.updateManyEmbeddedAppPermissions');

                }
            }
            if (selectedreports.length > 0) {
                if (oldreportsList.length > 0 && reportstoremove.length > 0) {
                    await addTeamReportPermission({
                        variables: {
                            reportId: reportstoremove,
                            input: {
                                canRead: false
                            },
                            teamId: newTeam._id,
                            tenantId: tenant._id
                        },
                    })
                }
                const teampermission = await addTeamReportPermission({
                    variables: {
                        reportId: selectedreports,
                        input: {
                            canRead: true
                        },
                        teamId: newTeam._id,
                        tenantId: tenant._id
                    },
                })
                newpermissions = _.get(teampermission, 'data.updateManyReportsPermissions');



            }
            if (selectedreports.length === 0) {
                if (oldreportsList.length > 0 && reportschanged) {
                    const teampermission = await addTeamReportPermission({
                        variables: {
                            reportId: reportstoremove,
                            input: {
                                canRead: false
                            },
                            teamId: newTeam._id,
                            tenantId: tenant._id
                        },

                    })
                    newpermissions = _.get(teampermission, 'data.updateManyReportsPermissions');
                }
                if (oldreportsList.length > 0 && !reportschanged) {
                    const teampermission = await addTeamReportPermission({
                        variables: {
                            reportId: reportstoremove,
                            input: {
                                canRead: true
                            },
                            teamId: newTeam._id,
                            tenantId: tenant._id
                        },

                    })
                    newpermissions = _.get(teampermission, 'data.updateManyReportsPermissions');

                }
            }

            let newmemebers;
            if (selectedusers.length > 0) {
                if (oldmemebers.length > 0 && userstoremove.length > 0) {
                    await removeTeamUsers({
                        variables: {
                            teamId: newTeam._id,
                            userId: userstoremove
                        },
                    })
                }
                const teamusers = await addTeamUsers({
                    variables: {
                        teamId: newTeam._id,
                        userId: selectedusers
                    },
                })
                newmemebers = _.get(teamusers, 'data.addManyUsersToTeam');


            }

            if (selectedusers.length === 0) {
                if (oldmemebers.length > 0 && userschanged) {
                    const teamusers = await removeTeamUsers({
                        variables: {
                            teamId: newTeam._id,
                            userId: userstoremove
                        },
                    })
                    newmemebers = _.get(teamusers, 'data.removeManyUsersFromTeam');
                }
            }
            setSelectedApps([])
            setSelectedUsers([])
            setSelectedReports([])
            SetUsersChanged(false)
            SetAppsChanged(false)
            SetReportsChanged(false)
            
            if (newpermissions) {
                newteamitem.push(newmemebers ? newmemebers : newpermissions)
            } else {
                newteamitem.push(newmemebers ? newmemebers : newTeam)
            }

            const teams = newteamitem.map(c => {
                return {
                    id: _.get(c, "_id"),
                    name: _.get(c, "displayName"),
                    members: _.get(c, "members"),
                    permissions: _.get(c, "teampermissions"),

                };
            });

            updateteams(teams[0])
            newteamitem.pop();
            handleClose()
        }
        catch (error) {
            alert(error);
        }
    };

    if (loadingListUsers || errorListUsers) {
        return <CenteredProgress />;
    }

    return (
        <>
            <Modal
                aria-labelledby="modal-title"
                open={showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style} >
                    <Grid container>
                        <Grid item xs={12} style={{ backgroundColor: '#1f576c' }}>
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                style={{ padding: '10px', color: '#fff', textAlign: 'center' }}
                            >
                                Update Team
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Container>
                                <form className="editteam" onSubmit={handleSubmit}>
                                    <div>
                                        <label className="fields">
                                            Team Name:
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="TeamName"
                                                defaultValue={file.name}
                                                required
                                            />
                                        </label>
                                        {formError &&
                                            <span className='error'>{formError}</span>}
                                    </div>
                                    <div>

                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please define if team member can upload/download files
                                        </Typography>
                                        {oldpermissions.find((item) => item.includes("upload")) ? <input
                                            type="checkbox"
                                            value="canupload"
                                            defaultChecked={true}
                                        /> : <input
                                            type="checkbox"
                                            value="canupload"

                                        />}
                                        <label className="teamability" htmlFor="canupload">Upload</label>
                                        {oldpermissions.find((item) => item.includes("download")) ? <input
                                            type="checkbox"
                                            value="candownload"
                                            defaultChecked={true}
                                        /> : <input
                                            type="checkbox"
                                            value="candownload"
                                        />}

                                        <label className="teamability" htmlFor="candownload">Download</label>
                                    </div>
                                    <div>
                                        {embeddedAppList.length > 0 ? <><Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please select which apps this team could read
                                        </Typography>
                                            <Select
                                                defaultValue={oldappslist}
                                                isMulti
                                                onChange={(options) => {
                                                    if (Array.isArray(options)) {
                                                        setSelectedApps(options.map((opt) => opt.value));
                                                        SetAppsChanged(true);
                                                    }
                                                }}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={embeddedAppList}
                                                id="addTeamApps"
                                                components={{
                                                    Option: InputOption
                                                }}
                                            /> </> : ''}

                                    </div>
                                    <div>
                                        {ReportsList.length > 0 ? <><Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please select which reports this team could read
                                        </Typography>
                                            <Select
                                                defaultValue={oldreportsList}
                                                isMulti
                                                onChange={(options) => {
                                                    if (Array.isArray(options)) {
                                                        setSelectedReports(options.map((opt) => opt.value));
                                                        SetReportsChanged(true);
                                                    }
                                                }}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={ReportsList}
                                                id="addTeamreports"
                                                components={{
                                                    Option: InputOption
                                                }}
                                            /> </> : ''}
                                    </div>
                                    <div>
                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please select team members
                                        </Typography>
                                        <Select
                                            defaultValue={oldmemberslist}
                                            isMulti
                                            maxMenuHeight={125}
                                            onChange={(options) => {
                                                if (Array.isArray(options)) {
                                                    setSelectedUsers(options.map((opt) => opt.value));
                                                    SetUsersChanged(true);
                                                }
                                            }}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            options={userList}
                                            id="addTeamMember"
                                            components={{
                                                Option: InputOption
                                            }}
                                        />

                                    </div>
                                    <div className="form-buttons">
                                        <Button className="cancel" onClick={onClose}>Cancel</Button>
                                        {/* <Button className="saveteam" onClick={handleSubmit}>Save Team</Button> */}
                                        <input type="submit" value="Save Team" data-testid="saveTeam" />
                                    </div>

                                </form>
                            </Container>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </>
    );

}
import * as React from 'react';
import _ from "lodash";
import { Grid, Container, Box, Tooltip, IconButton, Button } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import Crumbs from "../components/Crumbs";
import CenteredProgress from "../components/CenteredProgress";
import Alert from "@mui/material/Alert";
import { LIST_TEAMS_TENANT } from '../graphql/queryListTeamsOfTenant';
import MaterialReactTable from 'material-react-table';
import { useState, useEffect } from 'react';
import AddTeam from './AddTeam';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { REMOVE_TEAM } from '../graphql/mutationRemoveTeam';
import UpdateTeam from './UpdateTeam';
import { ARTIFACT_CONNECTION } from '../graphql/queryArtifactConnection';

const ConstructPermissions = ({ items, tenant, embeddedAppList, reportList, finalArray }) => {
    finalArray = [];
    const permissionArray = items.permissions.map(e => e.split(tenant._id)[1]);
    let filedatasetsList = [];
    let appsList = [];
    let reportsList = [];

    permissionArray.forEach(item => {
        if (item.includes("filedatasets")) {
            filedatasetsList.push(item.split("/")[1]);
        }
        else if (item.includes("apps")) {
            const itemId = item.split(":")[1].split("/")[0];
            const itemName = embeddedAppList.find(item => item._id === itemId);
            appsList.push(itemName.displayName);
        }
        else if (item.includes("reports")) {
            const itemId = item.split(":")[1].split("/")[0];
            const itemName = reportList.find(item => item._id === itemId);
            reportsList.push(itemName.displayName);
        }
    });

    if (filedatasetsList.length > 0) {
        finalArray.push("Files: " + filedatasetsList)
    }
    if (appsList.length > 0) {
        finalArray.push("Apps: " + appsList)
    }
    if (reportsList.length > 0) {
        finalArray.push("Reports: " + reportsList)
    }

    return (
        <Box>
            {finalArray.map((element, index) => (
                <><Box key={index} component="span" sx={{ p: 0, display: 'inline-block', borderBottom: '1px solid grey', marginBottom: '5px' }}>
                    {element}</Box><br /></>
            ))}
        </Box>
    )
}

export default function TenantTeams({ tenant, loading }) {
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [deletedteam, setDeletedTeam] = useState();
    const [selectedItem, setSelectedItem] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [removeTeam] = useMutation(REMOVE_TEAM);
    const Userabilities = _.get(tenant, 'abilities', []);
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const { loading: EmbeddedApploading,
        error: EmbeddedAppError,
        data: EmbeddedAppData, } = useQuery(ARTIFACT_CONNECTION, {
            variables: {
                tenantId: tenant ? tenant._id : '',
                filter: {
                    types: ["Report", "EmbeddedApp"],
                },
            }
        });
    const edges = _.get(EmbeddedAppData, "artifactConnection.edges", []);
    const list = edges ? edges.map((e) => e.node) : '';
    const embeddedAppList = list ? list.filter(e => e.__typename === 'EmbeddedApp') : '';
    const reportList = list ? list.filter(e => e.__typename === 'Report') : '';


    const { loading: loadingListTeams,
        error: errorListTeams,
        data: dataListTeams, } = useQuery(LIST_TEAMS_TENANT, {
            variables: {
                tenantId: tenant._id,
            },
            skip: !tenant,
        });

    useEffect(() => {
        const fetchData = async () => {
            if (!Data.length) {
                setIsLoading(true);
                if (dataListTeams) {
                    const TeamList = dataListTeams.listTeams;
                    const teams = TeamList.map(c => {
                        return {
                            id: _.get(c, "_id"),
                            name: _.get(c, "displayName"),
                            members: _.get(c, "members", []),
                            permissions: _.get(c, "teampermissions", []),

                        };
                    });
                    setData(teams);
                    setIsLoading(false);
                }
            } else {
                setIsRefetching(true);
            }

            setIsRefetching(false);
        }
        fetchData();

    }, [dataListTeams, Data.length]);

    const Removeteam = async (event) => {
        try {
            await removeTeam({
                variables: {
                    teamId: event.id
                },
            });

            setDeletedTeam(event)

        } catch (e) {
            alert(e)
            setDeletedTeam('')
        }
    }

    const handleDeleteRow = row => {

        if (
            !window.confirm(`Are you sure you want to delete ${row.name}`)
        ) {
            return;
        }
        Removeteam(row)
    };

    if (deletedteam) {
        function removeObjectWithId(arr, id) {
            return arr.filter((obj) => obj.id !== id);
        }
        const newArr = removeObjectWithId(Data, deletedteam.id);
        setData([...newArr]);
        setDeletedTeam('');
    }

    const updateteams = newdata => {
        const index = Data.findIndex(object => {
            return object.id === newdata.id;
        });
        if (index >= 0) {
            Data.splice(index, 1);
            setData([newdata, ...Data])
        } else {
            setData([newdata, ...Data])
        }

    }

    if (loadingListTeams || loading || EmbeddedApploading) {
        return <CenteredProgress />;
    } else if (errorListTeams || EmbeddedAppError) {
        <Alert severity="error">Oops! An error occured during the process!</Alert>;
    }

    const columns = [
        {
            accessorKey: 'name',
            header: 'Team Name',
            size: 100,
        },
        {
            header: 'Team Members',
            accessorFn: (rowData) => <><Box component="span" sx={{ p: '5px', marginBottom: '5px', display: 'block', width: '105px', borderRadius: '5px', backgroundColor: '#b8dce9' }}>{"(" + rowData.members.length + " Members)  "}</Box>  {rowData.members.map(e => <>
                <Box component="span" sx={{ p: '5px', border: '1px solid grey', borderRadius: "5px", marginBottom: '5px', display: 'inline-block' }}> {e.firstName + " " + e.lastName} </Box> <br /></>
            )}
            </>
        },
        {
            header: 'Team Permissions', accessorFn: rowData => <ConstructPermissions items={rowData} tenant={tenant} embeddedAppList={embeddedAppList} reportList={reportList} finalArray={[]} />
        },
        {
            accessorKey: 'id',
            header: 'ID',
            columnVisibility: false,
            enableEditing: false,
            size: 140,
        },
    ];

    return (
        <Container maxWidth="false" sx={{ width: "100%" }}>
            <Grid container spacing={2} rowSpacing={3}>
                <Grid item xs={12}>
                    <Crumbs
                        parts={[
                            {
                                displayName: tenant.displayName,
                                path: `/${tenant.name}`,
                            },
                            {
                                displayName: "teams",
                                path: `/t`,
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UpdateTeam tenant={tenant} teamlist={Data} updateteams={updateteams} file={selectedItem} showModal={showModal} embeddedApps={embeddedAppList} reportList={reportList}
                        onClose={() => setShowModal(false)} />
                </Grid>
                <Grid item xs={12}>
                    <MaterialReactTable
                        initialState={{ columnVisibility: { id: false } }}
                        enableDensityToggle={false}
                        enableColumnFilterModes={false}
                        enableColumnFilters={false}
                        enableColumnActions={false}
                        data={Data}
                        columns={columns}
                        enableRowActions
                        positionActionsColumn="last"
                        editingMode="modal"
                        enableEditing
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex' }}>
                                {Userabilities.canUpdateTeams ? (<Tooltip arrow placement="left" title="Edit">
                                    <IconButton onClick={() => {
                                        setShowModal(!showModal);
                                        setSelectedItem(row.original);
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>) : null}

                                {Userabilities.canRemoveTeams ? (<Tooltip arrow placement="right" title="Delete">
                                    <IconButton onClick={() => handleDeleteRow(row.original)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>) : null}

                            </Box>
                        )}
                        state={{
                            isLoading,
                            showProgressBars: isRefetching,
                        }}

                        renderTopToolbarCustomActions={() => (
                            <>
                            { Userabilities.canCreateTeam ? <Button data-testid="CreateNewTeam" onClick={() => setCreateModalOpen(true)} style={{ backgroundColor: '#1f576c', color: '#fff', float: 'right' }} component="span">Create New Team</Button> : ''}
                            </>
                            
                        )}
                    muiTableHeadCellProps={{
                        sx: {
                            backgroundColor: '#f5f5f5',
                            color: '#5e5d5d'
                        }
                    }}
                    />
                    <AddTeam tenant={tenant} teamlist={Data} updateteams={updateteams} open={createModalOpen}
                        onClose={() => setCreateModalOpen(false)} />
                </Grid>
            </Grid>
        </Container>
    );

}

import gql from 'graphql-tag';

export const REMOVE_USERS_FROM_TEAM = gql`
mutation RemoveManyUsersFromTeam($teamId: String!, $userId: [String!]!) {
    removeManyUsersFromTeam(teamId: $teamId, userId: $userId) {
      _id
      name
      displayName
      teampermissions
      abilities {
        canDownloadFromFileDatasets
        canUploadToFileDatasets
      }
      members {
        _id
        email
        firstName
        lastName
      }
    }
  }
`;
import { useQuery } from '@apollo/client';
import {
  Card,
  CardContent, Container, Grid, Stack
} from '@mui/material';
import IframeResizer from 'iframe-resizer-react';
import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router';
import { ArtifactHeaderCard } from '../components/ArtifactHeaderCard';
import { default as CenteredProgress, default as CircularIndeterminate } from '../components/CenteredProgress';
import NotAvailableMessage from '../components/NotAvailableMessage';
import { ARTIFACT_CONNECTION_DETAILED } from '../graphql/queryArtifactConnection';

const EmbeddedApp = ({ loading, tenant }) => {
  const params = useParams();
  if (loading) {
    return <CircularIndeterminate></CircularIndeterminate>;
  }
  const currentEmbeddedApptId = params.EmbeddedAppId;
  return (
    <EmbeddedApppanel tenant={tenant} EmbeddedAppId={currentEmbeddedApptId} />
  );
};

class Iframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };
  render() {
    return (
      <div className="container rsvp-wrapper">
        {this.state.loading ? <CenteredProgress /> : null}
        <IframeResizer
          src={this.props.src}
          style={{ width: '1px', minWidth: '100%', minHeight: '600px', overflow: 'scroll'}}
          onLoad={this.hideSpinner}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="yes"
        
        />
      </div>
    );
  }
}

const EmbeddedApppanel = ({ tenant, EmbeddedAppId }) => {
  const {
    loading: itemsLoading,
    error: itemsError,
    data: itemsData,
  } = useQuery(ARTIFACT_CONNECTION_DETAILED, {
    variables: {
      tenantId: tenant ? tenant._id : '',
      filter: {
        name: EmbeddedAppId,
      },
    },
  });

  if (itemsLoading || itemsError) {
    return <CenteredProgress />;
  }

  const embeddedapp = _.get(itemsData, 'artifactConnection.edges.0.node', []);
  if (!embeddedapp) {
    return <CenteredProgress />;
  }
  return (
    <Container maxWidth="false" sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Grid>
          <ArtifactHeaderCard
            breadcrumbs={[
              {
                displayName: tenant.displayName,
                path: `/${tenant.name}`,
              },
              {
                displayName: 'apps',
                path: `/${tenant.name}/a`,
              },
              {
                displayName: embeddedapp.displayName,
                path: `/${tenant.name}/a/${embeddedapp.name}`,
              },
            ]}
            artifact={embeddedapp}
          />
        </Grid>
        <Grid>
          <Card>
            <CardContent sx={{ my: 3 }}>
              {embeddedapp.url ? (
                <Iframe src={embeddedapp.url} />
              ) : (
                <NotAvailableMessage item={'URLs for this app'} />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Stack>
    </Container>
  );
};

export default EmbeddedApp;

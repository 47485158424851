import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Grid, Button, Tooltip, IconButton, Box, Typography } from '@mui/material';
import Crumbs from '../components/Crumbs';
import MaterialReactTable from 'material-react-table';
import { LIST_CONDUIT } from '../graphql/queryListConduits';
import CenteredProgress from "../components/CenteredProgress";
import Alert from "@mui/material/Alert";
import SimpleDateTime from 'react-simple-timestamp-to-date';
import CreateConduit from './CreateConduit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DELETE_CONDUIT } from '../graphql/mutationDeleteConduit';
import UpdateConduit from './UpdateConduit';
import { ArtifactType } from '../components/ArtifactType';
import { CURRENTUSER_ROLE } from '../graphql/queryCurrentUserRole';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const HandleStatus = ({ data }) => {
    if (data.Status === 'Succeeded') {
        return <Tooltip title="Succeeded" data-testid="Succeeded"><CheckIcon style={{ marginLeft: '10px', color: '#757575' }} /></Tooltip>
    }
    if (data.Status === 'Failed') {
        return <Tooltip title="Failed" data-testid="Failed"><CloseIcon style={{ marginLeft: '10px', color: '#757575' }} /></Tooltip>
    }
    if (data.Status === 'InProgress') {
        return <Tooltip title="InProgress" data-testid="InProgress"><AutorenewIcon style={{ marginLeft: '10px', color: '#757575' }} /></Tooltip>
    }
    if (data.Status === 'Cancelled') {
        return <Tooltip title="Cancelled" data-testid="Cancelled"><CloseIcon style={{ marginLeft: '10px', color: '#757575' }} /></Tooltip>
    }
    return null;

};

export default function ConduitList({ tenant, loading }) {
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deletedConduit, setdeletedConduit] = useState();
    const [selectedItem, setSelectedItem] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [deleteConduit] = useMutation(DELETE_CONDUIT);

    const columns = [
        {
            header: 'Name',
            width: '180',
            size: '180',
            maxSize: '180',
            accessorFn: (rowData) => <Typography style={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis', width: '180px' }}>{rowData.name}</Typography>
        },
        {

            header: 'From Artifact',
            size: '180',
            maxSize: '180',
            width: '180',
            accessorFn: (rowData) =>
                rowData.fromArtifactType ?
                    <>
                        <ArtifactType artifactName={rowData.fromArtiafact} artifactType={rowData.fromArtifactType} />
                    </> : <><Typography>{rowData.fromArtiafact}</Typography></>

        },
        {
            header: 'To Artifact',
            size: '180',
            maxSize: '180',
            width: '180',
            accessorFn: (rowData) =>
                rowData.toArtifactType ?
                    <>
                        <ArtifactType artifactName={rowData.toArtifact} artifactType={rowData.toArtifactType} />
                    </> : <><Typography>{rowData.toArtifact}</Typography></>
        },
        {
            accessorKey: 'LastUpdate',
            header: 'Last Update',
            accessorFn: (rowData) =>
                rowData.LastUpdate ?
                    SimpleDateTime({
                        dateSeparator: '-',
                        format: 'YMD',
                        timeSeparator: ':',
                        showTime: '1',
                        children: _.get(rowData, 'LastUpdate'),
                    }) : '',
            size: 100
        },
        {
            header: 'Status',
            accessorFn: (rowData) => rowData.Status ? <HandleStatus data={rowData} /> : '',
            size: 80,

        },
        {
            accessorKey: 'id',
            header: 'ID',
            columnVisibility: false,
            enableEditing: false,
        },
        {
            accessorKey: 'description',
            header: 'Description',
            columnVisibility: false,
            enableEditing: false,
        },
        {
            accessorKey: 'dataFactoryName',
            header: 'Data Factory Name',
            columnVisibility: false,
            enableEditing: false,
        },
        {
            accessorKey: 'piplineName',
            header: 'Pipline Name',
            columnVisibility: false,
            enableEditing: false,

        },
    ]
    const sortItems = (array) => {
        array.sort(function (a, b) {
            const sortitem = new Date(b.LastUpdate) - new Date(a.LastUpdate);
            return sortitem;
        });
    };
    const {
        loading: loadingListConduit,
        error: errorListConduit,
        data: dataListConduit,
    } = useQuery(LIST_CONDUIT, {
        variables: {
            tenantId: tenant._id,
        },
        skip: !tenant,
    });

    const { loading: loadingCurrentUser,
        data: currentuserData, } = useQuery(CURRENTUSER_ROLE, {
            variables: {
                tenantId: tenant ? tenant._id : '',
            },
        });
    const currentuserItems = _.get(currentuserData, "findCurrentUserInTenant", []);
    function checkCurrentUserRole(arr) {
        let userRole = arr.find((item) => item.role === 'OWNER')
        if (userRole) {
            return true
        } else {
            return false;
        }

    }
    const hasCurrentUserAccess = currentuserItems.length > 0 ? checkCurrentUserRole(currentuserItems) : false;
    
    useEffect(() => {
        const fetchData = async () => {
            if (!Data.length) {
                setIsLoading(true);
                if (dataListConduit) {
                    const conduitList = dataListConduit.listConduits;
                    const conduits = conduitList.map(item => {
                        return {
                            id: _.get(item, "_id"),
                            name: _.get(item, 'name'),
                            fromArtiafact: _.get(item, 'fromArtifact.displayName'),
                            toArtifact: _.get(item, 'toArtifact.displayName'),
                            LastUpdate: _.get(item, 'lastConduitRun.finishedAt'),
                            Status: _.get(item, 'lastConduitRun.status'),
                            description: _.get(item, 'description'),
                            dataFactoryName: _.get(item, 'dataFactoryName'),
                            piplineName: _.get(item, 'pipelineName'),
                            fromArtifactType: _.get(item, 'fromArtifact.artifactType'),
                            toArtifactType: _.get(item, 'toArtifact.artifactType'),

                        };
                    });
                    sortItems(conduits);
                    setData(conduits);
                    setIsLoading(false);
                }
            } else {
                setIsRefetching(true);
            }

            setIsRefetching(false);
        }
        fetchData();

    }, [dataListConduit, Data.length]);

    const handleNewRow = newdata => {
        const index = Data.findIndex(object => {
            return object.id === newdata.id;
        });
        if (index >= 0) {
            Data.splice(index, 1);
            setData([newdata, ...Data])
        } else {
            setData([newdata, ...Data])
        }
    }

    const removeConduit = async (event) => {
        try {
            await deleteConduit({
                variables: {
                    deleteConduitId: event.id,
                    tenantId: tenant._id
                },
            });

            setdeletedConduit(event)

        } catch (e) {
            alert(e)
            setdeletedConduit('')
        }
    }

    const handleDeleteRow = row => {

        if (
            !window.confirm(`Are you sure you want to delete ${row.name}`)
        ) {
            return;
        }
        removeConduit(row)
    };

    if (deletedConduit) {
        function removeObjectWithId(arr, id) {
            return arr.filter((obj) => obj.id !== id);
        }
        const newArr = removeObjectWithId(Data, deletedConduit.id);
        setData([...newArr]);
        setdeletedConduit('');
    }

    if (loadingListConduit || loadingCurrentUser) {
        return <CenteredProgress />;
    } else if (errorListConduit) {
        <Alert severity="error">Oops! An error occured during the process!</Alert>;
    }
    
    return (
        <Container maxWidth="false" sx={{ width: '100%' }}>
            <Grid container spacing={2} rowSpacing={3}>
                <Grid item xs={12}>
                    <Crumbs
                        parts={[
                            {
                                displayName: tenant.displayName,
                                path: `/${tenant.name}`,
                            },
                            {
                                displayName: 'Conduit List',
                                path: `/cl`,
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <UpdateConduit tenant={tenant} handleNewRow={handleNewRow} file={selectedItem} showModal={showModal}
                        onClose={() => setShowModal(false)} />
                </Grid>
                <Grid item xs={12}>
                    <MaterialReactTable
                        initialState={{ columnVisibility: { id: false, description: false, dataFactoryName: false, piplineName: false } }}
                        enableDensityToggle={false}
                        enableColumnFilterModes={false}
                        enableColumnFilters={false}
                        enableColumnActions={false}
                        data={Data}
                        columns={columns}
                        enableRowActions
                        positionActionsColumn="last"
                        editingMode="modal"
                        enableEditing
                        // layoutMode="grid"
                        renderRowActions={({ row, table }) => (
                            hasCurrentUserAccess ?
                                <Box sx={{ display: 'flex' }}>
                                    <Tooltip arrow placement="left" title="Edit">
                                        <IconButton onClick={() => {
                                            setShowModal(!showModal);
                                            setSelectedItem(row.original);
                                        }}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip arrow placement="right" title="Delete">
                                        <IconButton onClick={() => handleDeleteRow(row.original)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box> : ''
                        )}
                        state={{
                            isLoading,
                            showProgressBars: isRefetching,
                        }}
                        renderTopToolbarCustomActions={() => (

                            hasCurrentUserAccess ? <Button data-testid="CreateNewConduit" onClick={() => setCreateModalOpen(true)} style={{ backgroundColor: '#1f576c', color: '#fff', float: 'right' }} component="span">Create New Conduit</Button> : <Button></Button>
                        )}
                        muiTableHeadCellProps={{
                            sx: {
                                backgroundColor: '#f5f5f5',
                                color: '#5e5d5d'
                            }
                        }}

                    />
                    <CreateConduit tenant={tenant} handleNewRow={handleNewRow} open={createModalOpen}
                        onClose={() => setCreateModalOpen(false)} />
                </Grid>
            </Grid>
        </Container>
    )

}
import gql from 'graphql-tag';

export const UPDATE_TEAM = gql`
mutation UpdateTeam($input: UpdateTeamInput!, $teamId: String!, $tenantId: String!) {
  updateTeam(input: $input, teamId: $teamId, tenantId: $tenantId) {
    _id
    name
    displayName
    teampermissions
    abilities {
      canDownloadFromFileDatasets
      canUploadToFileDatasets
    }
    members {
      _id
      email
      firstName
      lastName
    }
  }
}
`;
import { Chip } from '@mui/material';
import { ArtifactIcon } from './ArtifactIcon';

const NAMES = {
    Dataset: 'Files',
    TableDataset: 'Table',
    Report: 'Report',
    FileDataset: 'Files',
    App: 'RhumbixApp',
    File: 'Files',
    Table: 'Table',
    EmbeddedApp: 'Apps',
    Apps: "Apps"

};

export const ArtifactChip = ({ artifact }) => {
    return (
        <Chip
            icon={<ArtifactIcon item={artifact.__typename} size={16} />}
            sx={{ p: '5px' }}
            label={NAMES[artifact.__typename]}
            variant="outlined"
            size="small"
        />
    );
};

import gql from 'graphql-tag';

export const CREATE_CONDUIT = gql`
mutation CreateConduit($input: CreateConduitInput!, $tenantId: String!) {
    createConduit(input: $input, tenantId: $tenantId) {
      _id
      name
      description
      dataFactoryName
      pipelineName
      fromArtifact {
        ... on App {
          artifactType
          displayName
        }
        ... on EmbeddedApp {
          artifactType
          displayName
        }
        ... on FileDataset {
          artifactType
          displayName
        }
        ... on Report {
          artifactType
          displayName
        }
        ... on TableDataset {
          artifactType
          displayName
        }
      }
      toArtifact {
        ... on App {
          artifactType
          displayName
        }
        ... on EmbeddedApp {
          artifactType
          displayName
        }
        ... on FileDataset {
          artifactType
          displayName
        }
        ... on Report {
          artifactType
          displayName
        }
        ... on TableDataset {
          artifactType
          displayName
        }
      }
      conduitRuns {
        finishedAt
        status
        successful
      }
    }
  }  

`;
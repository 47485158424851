import gql from "graphql-tag";

export const REMOVE_USER_FROM_TENANT = gql`
  mutation RemoveUserFromTenant($tenantId: String!, $userId: String!) {
    removeUserFromTenant(tenantId: $tenantId, userId: $userId) {
      _id
      displayName
      tenantUsers {
        user {
          _id
          email
          firstName
          lastName
        }
        role
      }
    }
  }
`;
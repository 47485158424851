import gql from 'graphql-tag';
export const UPDATE_USER = gql`
mutation UpdateUser($input: UpdateUserInput!, $tenantId: String!) {
  updateUser(input: $input, tenantId: $tenantId) {
    _id
    email
    firstName
    lastName
  }
}
`;


import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { ARTIFACT_CONNECTION } from '../graphql/queryArtifactConnection';
import { ArtifactChip } from './ArtifactChip';
import CenteredProgress from './CenteredProgress';
import RouterListItem from './RouterListItem';
import NotAvailableMessage from './NotAvailableMessage';

const useStyles = makeStyles((theme) => ({
  artifactsSelect: {
    padding: '9px 14px',
  },
}));


function compareStrings(a, b) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a.localeCompare(b);
}

function sortArtifacts(artifacts, isAlphabetical) {
  if (isAlphabetical) {
    return _.chain(artifacts)
      .filter((a) => a.__typename !== 'App')
      .sort((a, b) => compareStrings(a.displayName, b.displayName))
      .value();
  }

  //sort artifacts by types
  const sortingArr = [
    'Report',
    'EmbeddedApp',
    'Apps',
    'File',
    'FileDataset',
    'Table',
    'TableDataset',
  ];
  return _.chain(artifacts)
    .filter((a) => a.__typename !== 'App')
    .sort(
      (a, b) =>
        sortingArr.indexOf(a.__typename) - sortingArr.indexOf(b.__typename)
    )
    .value();
}

function FilterType({ options, selected, setSelected }) {
  const classes = useStyles();
  const isAllSelected =
    options.length > 0 && selected.length === options.length;
  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 100,
      },
    },
  };

  
  return (
    <div>
      <FormControl style={{ width: '100%' }} sx={{ m: 0 }}>
        <InputLabel
          style={{ color: '#cacaca', lineHeight: '13px' }}
          id="multiple-select-tenant-id"
        >
          Filter Artifacts
        </InputLabel>
        <Select
          classes={{ root: classes.artifactsSelect }}
          labelId="multiple-select-tenant-id"
          id="multiple-select-tenant-id"
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          <MenuItem value="all" style={{ width: '100%', padding: '6px 15px' }}>
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {options.map((option, idx) => (
            <MenuItem
              style={{ width: '100%', padding: '6px 15px' }}
              key={option}
              value={option}
            >
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default function ArtifactList({ artifactTypes, tenant , itemName}) {
  const [searchText, setSearchText] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sortalphabet, setSortAlphabet] = useState(false);


  let search = null;
  if (searchText?.trim().length > 0) {
    search = searchText;
  }

  let types = [];
  if (selectedTypes?.length > 0) {
    // builds the filter if there is a selection in the filter
    if (selectedTypes.includes('Apps')) {
      types.push('EmbeddedApp');
    }
    if (selectedTypes.includes('Report')) {
      types.push('Report');
    }
    if (selectedTypes.includes('File')) {
      types.push('FileDataset');
    }
    if (selectedTypes.includes('Table')) {
      types.push('TableDataset');
    }
  } else {
    // builds if there is not
    if (artifactTypes.includes('Apps')) {
      types.push('EmbeddedApp');
    }
    if (artifactTypes.includes('Report')) {
      types.push('Report');
    }
    if (artifactTypes.includes('File')) {
      types.push('FileDataset');
    }
    if (artifactTypes.includes('Table')) {
      types.push('TableDataset');
    }
  }

  const variables = {
    tenantId: tenant ? tenant._id : '',
    filter: {
      search,
      types,
    },
  };

  let artifacts = null;
  const { loading, data } = useQuery(ARTIFACT_CONNECTION, { variables });


  if (data) {
    const edges = _.get(data, 'artifactConnection.edges', []);
    const nodes = edges.map((e) => e.node);
    artifacts = sortArtifacts(nodes, sortalphabet);
  }

  const changeSearchText = _.debounce((inputString) => {
    setSearchText(inputString);
  }, 500);

  const onTypeChange = _.debounce((selected) => {
    setSelectedTypes(selected);
  }, 500);

  const today = new Date(),
  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds(),
  date = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();
  

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} sm={8} md={8}>
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={`Search within '${tenant.displayName}'`}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => changeSearchText(e.target.value)}
          />
          <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>

      {artifactTypes.length > 1 ? (
        <Grid item xs={6} sm={3} md={3}>
          <FilterType
            options={artifactTypes}
            setSelected={onTypeChange}
            selected={selectedTypes}
          />
        </Grid>
      ) : null}
      <Grid item xs={1} sm={1} md={1}>
        <ToggleButton
          style={{
            padding: '8px',
            minHeight: '40px',
            borderRadius: '5px',
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
          color="primary"
          value="check"
          selected={sortalphabet}
          onChange={() => {
            setSortAlphabet(!sortalphabet);
          }}
        >
          <Tooltip title="Sort Alphabetically">
            <FilterListIcon />
          </Tooltip>
        </ToggleButton>
      </Grid>
      
      { !loading && _.isEmpty(artifacts) && <NotAvailableMessage item={itemName} /> }
      <Grid item md={12}>
        <Paper elevation={0}>
          <List>
            {loading ? (
              <CenteredProgress />
            ) : (
              artifacts.map((item, idx) => {
                let sep = '';
                if (
                  item.__typename === 'FileDataset' ||
                  (item.__typename === 'TableDataset') |
                    (item.__typename === 'Dataset')
                ) {
                  sep = 'd';
                } else if (
                  item.__typename === 'EmbeddedApp' ||
                  item.__typename === 'Apps'
                ) {
                  sep = 'a';
                } else {
                  sep = 'r';
                }

                return (
                  <RouterListItem
                    key={idx}
                    to={`/${tenant.name}/${sep}/${item.name}`}
                    displayName={item.displayName}
                    description={item.description}
                    type={item.__typename}
                    tenantName={tenant.displayName}
                    tenantId={tenant._id}
                    time={time}
                    date={date}
                    icon={<ArtifactChip artifact={item} />}
                  />
                  
                );
              })
            )}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
}

import gql from "graphql-tag";

export const CREATE_TENANT = gql`
  mutation CreateTenant($input: CreateTenantInput!) {
    createTenant(input: $input) {
      _id
      displayName
      name
      kvUri
      domain
    }
  }
`;

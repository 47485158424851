import gql from 'graphql-tag';

export const ADD_GLOBALOWNER = gql`
mutation AddGlobalOwner($userId: String!) {
    addGlobalOwner(userId: $userId) {
        _id
        displayName
        name
    }
}
`
import { Button, Grid, Box, IconButton, Snackbar, styled, Tab, Tabs } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";

import Crumbs from "./Crumbs";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_TENANTS } from "../graphql/querySession";
import Alert from "@mui/material/Alert";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CenteredProgress from "./CenteredProgress";
import { CreateNewTenantModal } from "../pages/CreateTenant";
import { UPDATE_TENANT } from "../graphql/mutationUpdateTenant";
import { REFTELCT_ARTIFACTS } from "../graphql/mutationReflectArtifacts";
import Artifacts from "../components/ArtifactsConfig";
import GlobalUsers from "./GlobalUserConfig";
import {
  Sync as SyncIcon,
  Edit as EditIcon,
  ErrorOutline as ErrorIcon
} from '@mui/icons-material';


// Styled Snackbar component for success message
const SuccessSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "#6ef594", // Light green color
    fontWeight: "bold",
  },
}));

// Styled Snackbar component for error message
const ErrorSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "#ff8987",
    fontWeight: "bold",
  },
}));

// Styled Snackbar component for Loading message
const LoadingSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: "#a6efff",
    fontWeight: "bold",
  },
}));


export default function Tenants() {
  const [updateTenant] = useMutation(UPDATE_TENANT);
  const [tableData, setTableData] = useState([]);
  const [createTenantModalOpen, setCreateTenantModalOpen] = useState(false);
  const [performReflectArtifactMutation] = useMutation(REFTELCT_ARTIFACTS)
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const columns = [
    {
      accessorKey: "displayName",
      header: "Display Name",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "domain",
      header: "Domain",
    },
    {
      accessorKey: "kvUri",
      header: "KEY VAULT",
    },
    {
      accessorKey: 'id',
      header: 'ID',
      enableHiding: true,
    }
  ];

  const { loading: loadingTenants, error: errorTenants, data: dataTenants } = useQuery(QUERY_TENANTS);

  useEffect(() => {
    if (!tableData.length) {
      if (dataTenants) {
        const tenants = dataTenants.listTenants.map((tenant) => {
          return {
            id: tenant._id,
            displayName: tenant.displayName,
            name: tenant.name,
            kvUri: tenant.kvUri,
            domain: tenant.domain,
          };
        });
        setTableData(tenants);
      }
    }
  }, [dataTenants, tableData]);

  if (loadingTenants) {
    return <CenteredProgress />;
  } else if (errorTenants) {
    return <Alert severity="error">Oops! An error occurred during the process!</Alert>;
  }

  const handleCreateNewRow = async (values) => {
    setTableData([...tableData, values]);
  };


  const handleReflectClick = (row) => {
    setShowLoadingMessage(true); // Show loading message Snackbar
    performReflectArtifactMutation({ variables: { tenantId: row.original._id ? row.original._id : row.original.id } })
      .then(data => {
        // Mutation success logic...
        setShowSuccessMessage(true); // Show success message Snackbar
        setShowLoadingMessage(false); // Hide loading message Snackbar
      })
      .catch(error => {
        // Mutation error logic...
        setShowErrorMessage(true); // Show error message Snackbar
        setShowLoadingMessage(false); // Hide loading message Snackbar
        console.error(error);
      });
  };



  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    try {
      await updateTenant({
        variables: {
          input: {
            displayName: values.displayName,
            domain: values.domain,
            kvUri: values.kvUri,
            name: values.name,
          },
          tenantId: row.original._id ? row.original._id : row.original.id,
        },
      });

      // Update the table data with the updated row values
      const updatedData = tableData.map((dataRow) => {
        if (dataRow.id === row.original.id) {
          return {
            ...dataRow,
            displayName: values.displayName,
            domain: values.domain,
            kvUri: values.kvUri,
            name: values.name,
          };
        }
        return dataRow;
      });

      setTableData(updatedData);
    } catch (error) {
      alert(error);
    }
    exitEditingMode();
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };


  return (
    <Container maxWidth="false" sx={{ width: "100%" }}>
      <Grid>
        {/* navigation crumbs */}
        <Grid item xs={12} style={{paddingBottom: '20px'}}>
          <Crumbs
            parts={[
              {
                displayName: "Configurations",
                path: "/configs",
              },
            ]}
          />
        </Grid>
        {/* Tab Navigation */}
        <Grid item xs={12} style={{ border: '1px solid #fff', backgroundColor: 'rgba(31, 87, 108, 0.3)'}}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="#1f576c"
            variant="fullWidth"
            
          >

            <Tab label="Tenant Settings" />
            <Tab label="Artifact Settings" />
            <Tab label="Permission Settings" />
          </Tabs>
        </Grid>
        {/* Tab Content */}
        <Grid item xs={12}>
          {currentTab === 0 &&
            <Grid item xs={12}>
            <MaterialReactTable
              initialState={{ columnVisibility: { id: false } }}
              data={tableData}
              enableRowActions
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                  <IconButton
                    color="primary"
                    title="Reflect Artifacts"
                    onClick={() => {
                      handleReflectClick(row);
                    }}
                  >
                    <SyncIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    title="Edit Tenant"
                    onClick={() => {
                      table.setEditingRow(row);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
              onEditingRowSave={handleSaveRow}
              columns={columns}
              positionActionsColumn="last"
              renderTopToolbarCustomActions={() => (
                <Button
                  onClick={() => setCreateTenantModalOpen(!createTenantModalOpen)}
                  style={{
                    backgroundColor: "#1f576c",
                    color: "#fff",
                    float: "right",
                  }}
                  component="span"
                >
                  Create New Tenant
                </Button>
              )}
            />
            <CreateNewTenantModal
              open={createTenantModalOpen}
              columns={columns}
              onClose={() => setCreateTenantModalOpen(!createTenantModalOpen)}
              handleCreateNewRow={handleCreateNewRow}
            />
          </Grid>
          }
          {currentTab === 1 && (
            <Artifacts />
          )}
          {currentTab === 2 && (
            <GlobalUsers />
          )}
        </Grid>
        {/* Tenants MaterialReactTable Grid */}
      </Grid>
      {showLoadingMessage && (
        <LoadingSnackbar
          open={showLoadingMessage}
          autoHideDuration={700}
          onClose={() => { }}
          message=
          {<>
            <SyncIcon style={{ marginLeft: "-5px", marginRight: "6px", marginBottom: "-8px" }} />
            Reflecting Artifacts in progress. You will receive an update...
          </>}
        />
      )}
      {showSuccessMessage && (
        <SuccessSnackbar
          open={showSuccessMessage}
          autoHideDuration={6000}
          onClose={() => setShowSuccessMessage(false)}
          message={
            <>

              <TaskAltIcon style={{ marginLeft: "-5px", marginRight: "8px", marginBottom: "-8px" }} />
              Reflecting Artifacts is done successfully!
            </>
          }
        />
      )}
      {showErrorMessage && (
        <ErrorSnackbar
          open={showErrorMessage}
          autoHideDuration={6000}
          onClose={() => setShowErrorMessage(false)}
          message={
            <>
              <ErrorIcon style={{ marginLeft: "-5px", marginRight: "8px", marginBottom: "-8px" }} />
              Oops! We've encountered a hiccup...
            </>}
        />
      )}
    </Container>
  );
}
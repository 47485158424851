import gql from "graphql-tag";
export const LIST_USERS_TENANT = gql`
query ListUsersOfTenant($input: ListTenantInput!) {
  findOneTenant(input: $input) {
    tenantUsers {
      role
      user {
        email
        firstName
        lastName
        _id
      }
    }
    displayName
  }
}
`;

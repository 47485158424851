import gql from "graphql-tag";

export const QUERY_SESSION = gql`
  query tenant {
    listTenants {
      name
      displayName
      _id
      abilities {
        canCreateTeam
        canDownloadFromFileDatasets
        canReadTeams
        canRemoveTeams
        canUpdateTeams
        canUploadToFileDatasets
      }
    }
    self {
      lastName
      firstName
      _id
      email
    }
  }
`;

export const QUERY_TENANTS = gql`
  query tenant {
    listTenants {
      _id
      name
      displayName
      domain
      kvUri
    }
  }
`;

export const QUERY_SESSION_WITH_TENANT = gql`
  query tenant($tenantName: String) {
    findOneTenant(input: { name: $tenantName }) {
      _id
      name
      displayName
      tenantUsers {
        role
        user {
          _id
          email
          firstName
          lastName
        }
      }
      abilities {
        canCreateTeam
        canDownloadFromFileDatasets
        canReadTeams
        canRemoveTeams
        canUpdateTeams
        canUploadToFileDatasets
      }
    }
    listTenants {
      name
      displayName
      _id
    }
    self {
      lastName
      firstName
      _id
      email
    }
  }
`;

import React from 'react';
import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { STORE_USER_TRACKING_DATA } from '../graphql/mutationStoreUserTrackingData';
import { useMutation } from '@apollo/client';

function RouterListItem(props) {
  const { to, displayName, description, icon, type, tenantName,tenantId } = props;
  const {authState} = useOktaAuth();
  const currentUser = authState.idToken.claims.name
  const userEmail = authState.idToken.claims.email
  const [sendEvent] = useMutation(STORE_USER_TRACKING_DATA)
  
  const sendTrackingEvent = async (eventData) => {
    try {
      await sendEvent({
        variables: {
          input: {
            event_type: 'User_Activity',
            event_data: eventData,
          },
        },
      });
    } catch (error) {
      console.log('Error tracking: ', error);
    }
  };
  
  const eventData={
    userName: currentUser,
    userEmail,
    itemName: displayName,
    description: description,
    type,
    tenantName: (tenantName !== undefined && tenantName !== 'NULL') ? tenantName : displayName,
    tenantId,
    
  }
  return (
    <ListItem
      alignItems="flex-start"
      button 
      component={RouterLink}
      to={to}
      sx={{ gap: 1 }}
      onClick={(event) => (sendTrackingEvent(eventData))}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={displayName}
        primaryTypographyProps={{ variant: 'h6' }}
        secondary={description}
      />
    </ListItem>
  );
}

export default RouterListItem;

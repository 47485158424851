import {
  Card,
  CardContent,
  CardActions,
  Tab,
  Tabs,
  Grid,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Crumbs from './Crumbs';
import { ArtifactChip } from './ArtifactChip';

const RegularCaseTab = withStyles({
  root: {
    textTransform: 'none',
  },
})(Tab);

export const ArtifactHeaderCard = ({
  breadcrumbs,
  artifact,
  tabs,
  selectedIndex,
  onSelectionChange,
  action
}) => {
  return (
    <Card>
      <CardContent>
        <Grid container direction="column" sx={{ gap: 2 }}>
          <Grid direction="row" container sx={{ gap: 2, alignItems: 'center' }}>
            <Grid item>
              <Crumbs parts={breadcrumbs} />
            </Grid>
            <Grid item>
              <ArtifactChip artifact={artifact} />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {artifact.displayName || artifact.pbiName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="p">
              {artifact.description || artifact.pbiDescription}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ border: 1, borderColor: 'lightgrey', p: '1px', justifyContent:"space-between" }}>
        <Tabs value={selectedIndex} onChange={onSelectionChange}>
          {tabs && tabs.map((t, i) => <RegularCaseTab label={t} key={i} />)}
        </Tabs>
        {action}
      </CardActions>
    </Card>
  );
};

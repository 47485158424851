import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";

const Crumbs = (props) => {
  const { parts } = props;

  return (
    <Breadcrumbs sx={{ my: 1 }}>
      <Link
        sx={{ fontSize: 14 }}
        color="text.secondary"
        to="/"
        component={RouterLink}
      >
        Home
      </Link>
      {parts.map((part, idx) => (
        <div key={part.path}>
          {idx !== parts.length - 1 ? (
            <Link
              sx={{ fontSize: 14 }}
              color="text.secondary"
              to={`${part.path}`}
              component={RouterLink}
            >
              {part.displayName}
            </Link>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Typography>{part.displayName}</Typography>
            </Box>
          )}
        </div>
      ))}
    </Breadcrumbs>
  );
};

export default Crumbs;

import gql from "graphql-tag";

export const REMOVE_GLOBALOWNER = gql`
      mutation RemoveGlobalOwner($userId: String!) {
        removeGlobalOwner(userId: $userId) {
          _id
          name
          displayName
        }
      }
    `;
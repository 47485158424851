import gql from "graphql-tag";
export const GLOBAL_OWNERS = gql`
query GlobalOwners {
  globalOwners {
    _id
    displayName
    members {
      _id
      firstName
      lastName
    }
  }
}
`

export const GLOBAL_OWNERS_WITH_SESSION = gql`
query GlobalOwners {
  globalOwners {
    members {
      _id
    }
  }
  self {
    _id
  }
}
`
import { useQuery } from '@apollo/client';
import { Card, CardContent, Container } from '@mui/material';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { ArtifactHeaderCard } from '../components/ArtifactHeaderCard';
import { ArtifactHealthTable } from '../components/ArtifactHealthTable';

import Box from '@mui/material/Box';
import _ from 'lodash';
import MaterialReactTable from 'material-react-table';
import PropTypes from 'prop-types';


const TableDataset = ({ dataset, tenant }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  const { data, loading } = useQuery(
    gql`
      query FindOneTableDataset(
        $after: String
        $first: Int
        $tenantId: String
        $input: ListTableDatasetInput
      ) {
        findOneTableDataset(tenantId: $tenantId, input: $input) {
          records(after: $after, first: $first) {
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
              totalDocs
              totalPages
            }
            edges {
              cursor
              node
            }
          }
        }
      }
    `,
    {
      variables: {
        after: String(pagination.pageIndex * pagination.pageSize),
        first: pagination.pageSize,
        tenantId: tenant._id,
        input: {
          name: dataset.name,
        },
      },
    }
  );

  const rows =
    data?.findOneTableDataset?.records.edges.map((r) => r.node) || [];

  // return {
  //   data,
  //   page: query.page,
  //   totalCount:
  //     result?.data?.findOneTableDataset?.records?.pageInfo?.totalDocs,
  // };

  const columns = [];
  const visibility = {};

  if (!_.isNull(dataset.columns)) {
    for (const c of dataset.columns) {
      if (!c.name.startsWith('__') || c.name === '__Id') {
        columns.push({
          header: c.name,
          accessorKey: c.name,
        });
      }
      _.set(visibility, c.name, !c.name.startsWith('__'));
    }
  }
  return (
    <Container maxWidth="false" sx={{ width: '100%' }}>
      <ArtifactHeaderCard
        breadcrumbs={[
          {
            displayName: tenant.displayName,
            path: `/${tenant.name}`,
          },
          {
            displayName: 'data',
            path: `/${tenant.name}/d`,
          },
          {
            displayName: dataset.displayName,
            path: `/${tenant.name}/d/${dataset.name}`,
          },
        ]}
        artifact={dataset}
        tabs={['Data', 'Health']}
        selectedIndex={value}
        onSelectionChange={handleChange}
      />

      <TabPanel value={value} index={0}>
        <Card>
          <CardContent sx={{ my: 3 }}>
            <MaterialReactTable
              enableColumnFilters={false}
              enablePagination={true}
              enableSorting={false}
              enableBottomToolbar={true}
              enableTopToolbar={true}
              enableRowActions={false}
              enableColumnActions={false}
              enableGlobalFilter={false}
              enableDensityToggle={false}
              initialState={{
                columnVisibility: visibility,
                density: 'compact',
              }}
              state={{
                isLoading: loading,
                pagination,
              }}
              columns={columns}
              data={rows}
              rowCount={data?.findOneTableDataset?.records?.pageInfo?.totalDocs}
              manualPagination={true}
              onPaginationChange={setPagination}
              muiTablePaginationProps={{
                sx: {
                  '.MuiTablePagination-menuItem': {
                    display: 'flex',
                  },
                },
              }}
            />

            {/* <MaterialTable
              icons={TABLE_ICONS}
              title=""
              options={{
                padding: 'dense',
                pageSizeOptions: [100],
                pageSize: 100,
                maxBodyHeight: `650px`,
                // Allow user to hide/show
                // columns from Columns Button
                emptyRowsWhenPaging: false,
                columnsButton: true,
                search: false,
                rowStyle: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              data={getMoreData}
              onChangePage
              columns={columns}
            /> */}
          </CardContent>
        </Card>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ArtifactHealthTable artifact={dataset} />
      </TabPanel>
    </Container>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TableDataset;

import { Button, Grid, Box, IconButton, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";

import { useMutation, useQuery } from "@apollo/client";
import { LIST_EMBEDDEDAPPS_WITH_TENANT } from "../graphql/queryEmbeddedApp";
import { CreateNewEmbeddedAppModal } from "../pages/CreateArtifact";
import { UPDATE_EMBEDDEDAPP } from "../graphql/mutationUpdateEmbeddedApp";
import { DELETE_EMBEDDEDAPP } from "../graphql/mutationDeleteEmbeddedApp";
import CenteredProgress from "./CenteredProgress";


import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';


export default function Artifacts() {
  const [tableData, setTableData] = useState([]);
  const [createArtifactModalOpen, setCreateArtifactModalOpen] = useState(false);
  const [updateEmbeddedApp] = useMutation(UPDATE_EMBEDDEDAPP);
  var tenantId = '';

  const columns = [
    {
      accessorKey: "displayName",
      header: "Display Name",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "url",
      header: "URL",
    },
    {
      accessorKey: "tenantId",
      header: "TenantId",
      enableEditing: false,
    },
    {
      accessorKey: "tenantName",
      header: "Tenant",
      enableEditing: false,
    },
    {
      accessorKey: "id",
      header: "ID",
      enableEditing: false,
    },
  ];

  const inputColumns = [
    {
      accessorKey: "displayName",
      header: "Display Name",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "url",
      header: "URL",
    },
  ];

  const { loading: loadingEmbeddedApps, error: errorEmbeddedApps, data: dataEmbeddedApps, refetch: refetchEmbeddedApps } = useQuery(LIST_EMBEDDEDAPPS_WITH_TENANT);
  const [deleteEmbeddedApp] = useMutation(DELETE_EMBEDDEDAPP);
  console.log("errorEmbeddedApps",errorEmbeddedApps);
  useEffect(() => {
    if (!tableData.length) {
      if (dataEmbeddedApps) {
        const embeddedApps = dataEmbeddedApps.listEmbeddedApps.map((embeddedApp) => {
          return {
            id: embeddedApp._id,
            displayName: embeddedApp.displayName,
            name: embeddedApp.name,
            description: embeddedApp.description,
            url: embeddedApp.url,
            tenantId: embeddedApp.tenant._id,
            tenantName: embeddedApp.tenant.displayName
          }
        })
        //refetchEmbeddedApps();
        setTableData(embeddedApps);
      }
    }
  }, [dataEmbeddedApps, tableData]);

  if (loadingEmbeddedApps) {
    return <CenteredProgress />;
  } else if (errorEmbeddedApps) {
    
    return <Alert severity="error">Oops! An error occurred during the process</Alert>;
  }
  

  const handleCreateNewRow = async (values) => {
    refetchEmbeddedApps();
    setTableData([...tableData, values]);
  };

  const handleDeleteRow = async (row) => {
    if (
      !window.confirm(`Are you sure you want to delete the embeddedApp?`)
    ) {
      return;
    } else {
      try {
        const deleteEmbeddedAppId = row.original._id || row.original.id;
        const tenantId = row.original.tenantId
        await deleteEmbeddedApp({ variables: { deleteEmbeddedAppId, tenantId } });

        await refetchEmbeddedApps();
        const updatedTableData = tableData.filter((rowData) => rowData.id !== deleteEmbeddedAppId);
        setTableData(updatedTableData);

      } catch (error) {
        console.error(error);
      }

    }

  };

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    try {
      tenantId = row.original.tenantId;
      await updateEmbeddedApp({
        variables: {
          input: {
            displayName: values.displayName,
            description: values.description,
            name: values.name,
            url: values.url,
          },
          updateEmbeddedAppId: row.original._id ? row.original._id : row.original.id,
          tenantId: tenantId,
        },
      });

      const updatedData = tableData.map((dataRow) => {
        if (dataRow.id === row.original.id) {
          return {
            ...dataRow,
            displayName: values.displayName,
            description: values.description,
            name: values.name,
            url: values.url,
            tenantId: tenantId,
            tenantName: values.tenantName
          };
        }
        return dataRow;
      });

      setTableData(updatedData);

    } catch (error) {
      alert(error);
    }
    exitEditingMode();
  };

  return (

    <Grid item xs={12} >
      <MaterialReactTable
        initialState={{ columnVisibility: { id: false, tenantId: false } }}
        data={tableData}
        enableRowActions
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <IconButton
              color="secondary"
              title="Edit Embedded App"
              onClick={() => {
                table.setEditingRow(row);
              }}
            >

              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              title="Remove Embedded App"
              onClick={() => {
                handleDeleteRow(row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        onEditingRowSave={handleSaveRow}
        columns={columns}
        positionActionsColumn="last"
        renderTopToolbarCustomActions={() => (
          <Button
            onClick={() => setCreateArtifactModalOpen(!createArtifactModalOpen)}
            style={{
              backgroundColor: "#1f576c",
              color: "#fff",
              float: "right",
            }}
            component="span"
          >
            Create New Embedded App
          </Button>
        )}
      />
      <CreateNewEmbeddedAppModal
        open={createArtifactModalOpen}
        columns={columns}
        inputColumns={inputColumns}
        onClose={() => setCreateArtifactModalOpen(!createArtifactModalOpen)}
        handleCreateNewRow={handleCreateNewRow}
      />
    </Grid>

  )
}
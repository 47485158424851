import gql from 'graphql-tag';

export const ADD_USERS_TO_TEAM = gql`
mutation AddManyUsersToTeam($teamId: String!, $userId: [String!]!) {
  addManyUsersToTeam(teamId: $teamId, userId: $userId) {
    _id
    name
    displayName
    teampermissions
    abilities {
      canUploadToFileDatasets
      canDownloadFromFileDatasets
    }
    members {
      _id
      email
      firstName
      lastName
    }

  }
}
`;
import gql from 'graphql-tag';

export const ADD_TEAM = gql`
mutation CreateTeam($input: CreateTeamInput!, $tenantId: String!) {
  createTeam(input: $input, tenantId: $tenantId) {
    _id
    abilities {
      canUploadToFileDatasets
      canDownloadFromFileDatasets
    }
    displayName
    members {
      _id
      email
      firstName
      lastName
    }
    name
    teampermissions
  }
}
`;
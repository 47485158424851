// Azure-storageblob
import {BlockBlobClient} from "@azure/storage-blob";

//should be change to server inputs
/*
const sasToken =
    process.env.storagesastoken ||
    "sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-06-11T22:44:25Z&st=2022-03-01T15:44:25Z&spr=https&sig=P4Xfu79IEmIFhtUsMBSA4EjOpEfJQ7dVfKkgJBOi%2FHU%3D";

const storageAccountName = process.env.storageresourcename || "dlcst"; // Storage resource name
*/

// Feature flag - disable storage feature to app if not configured
/*export const isStorageConfigured = () => {
    return !(!storageAccountName || !sasToken);
};*/

const uploadFileToBlob = async (url, file , user ) => {
    if (!file) return [];

    try {
        const options = {blobHTTPHeaders: {blobContentType: file.type} ,  };
        const blobClient = new BlockBlobClient(url);
        const metadata = {Upload: user}
        
        // upload file
        await blobClient.uploadData(file, options);
        await blobClient.setMetadata(metadata);
        return true;
        // await blobClient.setMetadata({UserName: "dbUserId"}); //OKTA Username/Email should be added
    } catch (e) {
        debugger;
        return false;
       
    }
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;

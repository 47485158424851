import React from "react";
import { Container, Stack , Typography} from "@mui/material";
import {styled } from "@mui/system";
import errorimg from  "../assets/images/error.png";
import AuthButton from "../layout/LogoutButton";


const ErrorImg = styled("img")({
    width: 300,
    height: "auto",
  });


export default function errormessage(props){
 
    return (
        <div>
        <Container style={{ backgroundColor: "#fff", padding:50  }}>
          <Stack justifyContent="center" alignItems="center" spacing={2}>
            <item><ErrorImg src={errorimg} alt="Error" /></item>
            <item><Typography variant="h4">{props.title}</Typography></item>
            <item><Typography>{props.description}</Typography></item>
            <item><AuthButton tcolor="#fff" bgcolor="#E57E3E" className="button"/></item>
          </Stack>
        </Container>
        
  
        </div>
        
      );

}
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  QUERY_SESSION,
  QUERY_SESSION_WITH_TENANT
} from '../graphql/querySession';
import CenteredProgress from './CenteredProgress';
import AppDrawer from './Drawer';
import { useOktaAuth } from '@okta/okta-react';
import { STORE_USER_TRACKING_DATA } from '../graphql/mutationStoreUserTrackingData';
import { useMutation } from '@apollo/client';

export default function DashboardContainer() {
  const params = useParams();

  const {authState} = useOktaAuth();

  const[hasTrackedLogin, setHasTrackedLogin] = useState(false);
  const [sendEvent] = useMutation(STORE_USER_TRACKING_DATA)

   const [session, setSession] = useState({
    tenant: null,
    currentUser: null,
    availableTenants: [],
    trackLogin: null,
  });

  let tenantName = params.tenantName;
  if (!tenantName) {
    const path = window.location.pathname;
    if (path !== '/' || path !== '/configs') {
      tenantName = path.split("/")[1];
    }
    if (path === '/u' || path === '/configs') {
      tenantName = null;
    }
  }
  const sendTrackingEvent = async (eventData) => {
    try {
      await sendEvent({
        variables: {
          input: {
            event_type: 'Login_History',
            event_data: eventData,
          },
        },
      });
    } catch (error) {
      console.log('Error tracking: ', error);
    }
  };

  const QUERY = tenantName ? QUERY_SESSION_WITH_TENANT : QUERY_SESSION;

  const { error, loading } = useQuery(QUERY, {
    variables: { tenantName },
    skip: tenantName && tenantName === session.tenant?.name,
    onCompleted: (data) => {
      const availableTenants = data.listTenants;
      const currentUser = data.self;
      let tenant = null;
      if (availableTenants.length === 1) {
        tenant = availableTenants[0];
      } else {
        tenant = tenantName && data.findOneTenant ? data.findOneTenant : null;
      }
      
      if (!hasTrackedLogin && !session.trackLogin) {
        const userEmail = authState.idToken.claims.email;
        const userName = authState.idToken.claims.name;
        const tenant = availableTenants.length === 1 ? availableTenants[0].displayName : availableTenants.map(tenant => tenant.displayName).join(', ');
        const trackingEvent = {
          userEmail: userEmail,
          userName: userName,
          tenantName: tenant,
        }
        sendTrackingEvent(trackingEvent);
        setHasTrackedLogin(true)
      }
      setSession({
        availableTenants,
        currentUser,
        tenant,
        hasTrackedLogin
      });
    },
  });

  if (loading) {
    return <CenteredProgress />;
  }
  if (tenantName && !session.tenant) {
    return <CenteredProgress />;
  }

  return (
    <Box sy={{ flex: 1 }}>
      <AppDrawer {...{ error, loading, ...session }} />
    </Box>
  );
}

import React, { useState } from 'react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import '../Form.css';
import { Grid, Box, Typography, Modal, Backdrop, Container, Button } from "@mui/material";
import { CREATE_CONDUIT } from '../graphql/mutationCreateConduit';
import Select, { components } from "react-select";
import { ARTIFACT_CONNECTION } from '../graphql/queryArtifactConnection';
import CenteredProgress from '../components/CenteredProgress';
import { ArtifactIcon } from '../components/ArtifactIcon';
import { Chip } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: '#fff',
    boxShadow: 24,
    padding: '0 !important',
    margin: '0 0 15px 0 !important'
};


const IconOption = (props) => {

    return (

        <components.Option
            {...props}
        >
            <Chip
                icon={<ArtifactIcon item={props.data.__typename} size={16} />}
                sx={{ marginTop: '9px', paddingLeft: '12px', border: 'none', marginRight: '1px' }}
                variant="outlined"
                size="small"
            />
            {props.children}
        </components.Option>
    );
};


export default function CreateConduit({ tenant, handleNewRow, open, onClose }) {
    const [formError, setFormError] = useState();
    const [createConduit] = useMutation(CREATE_CONDUIT);
    const [selectedfromArtifact, setselectedfromArtifact] = useState([]);
    const [selectedtoArtifact, setselectedtoArtifact] = useState([]);

    const { loading: allArtifactloading,
        error: allArtifactError,
        data: allArtifactData, } = useQuery(ARTIFACT_CONNECTION, {
            variables: {
                tenantId: tenant ? tenant._id : '',
            }
        });
    const edges = _.get(allArtifactData, "artifactConnection.edges", []);
    const items = edges ? edges.map((e) => e.node) : '';

    const artifactList = items ? items.map((a) => {
        return {
            value: a._id, label: a.name, __typename: a.__typename
        }
    }) : '';

    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        //Name Validation
        if (!value) {
            setFormError("**cannot be empty")
        }
        else if (typeof value !== "undefined") {
            if (!value.match(/^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$/)) {
                setFormError("**Only letters and numbers are accepted")
            } else {
                setFormError(" ")
            }
        }
    }

    const handlesubmit = async (event) => {
        event.preventDefault();
        try {
            const r = await createConduit({
                variables: {
                    input: {
                        name: event.target[0].value,
                        description: event.target[1].value,
                        dataFactoryName: event.target[2].value,
                        pipelineName: event.target[3].value,
                        type: "unkown",
                        virtual: false,
                        fromArtifact: selectedfromArtifact,
                        toArtifact: selectedtoArtifact,

                    },
                    tenantId: tenant._id
                },
            });

            const item = _.get(r, 'data.createConduit');
            const newConduit =
            {
                id: _.get(item, "_id"),
                name: _.get(item, 'name'),
                fromArtiafact: _.get(item, 'fromArtifact.displayName'),
                toArtifact: _.get(item, 'toArtifact.displayName'),
                LastUpdate: item.conduitRuns.finishedAt ? _.get(item, 'conduitRuns.finishedAt') : '',
                Status: item.conduitRuns.status ? _.get(item, 'conduitRuns.status') : '',
                description: _.get(item, 'description'),
                dataFactoryName: _.get(item, 'dataFactoryName'),
                piplineName: _.get(item, 'pipelineName'),
                fromArtifactType: _.get(item, 'fromArtifact.artifactType'),
                toArtifactType: _.get(item, 'toArtifact.artifactType'),
            };

            handleNewRow(newConduit)
        }
        catch (error) {
            alert(error);
        }
        onClose();
    };

    if (allArtifactloading || allArtifactError) {
        return <CenteredProgress />;
    }

    return (
        <>
            <Modal
                aria-labelledby="modal-title"
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style}>
                    <Grid container >
                        <Grid item xs={12} style={{ backgroundColor: '#1f576c' }}>
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                style={{ padding: '10px', color: '#fff', textAlign: 'center' }}
                            >
                                Create New Conduit
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Container>
                                <form className="addteam" onSubmit={handlesubmit}>
                                    <div>
                                        <label className="fields">
                                            Name
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="ConduitName"
                                                required
                                            />
                                        </label>
                                        {formError &&
                                            <span className='error'>{formError}</span>}
                                    </div>
                                    <div>
                                        <label className="fields">
                                            Description
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="Description"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <label className="fields">
                                            Data Factory Name
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="DataFactoryName"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <label className="fields">
                                            Pipeline Name
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="PipelineName"
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 10px 0px 5px', fontSize: '14px' }}
                                        >
                                            From Artifact
                                        </Typography>
                                        <Select
                                            defaultValue={[]}
                                            onChange={(option) => {
                                                setselectedfromArtifact(option.value);
                                            }}
                                            options={artifactList}
                                            isClearable
                                            isSearchable
                                            components={{ Option: IconOption }}
                                        />

                                    </div>
                                    <div>
                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 25px 0px 5px', fontSize: '14px' }}
                                        >
                                            To Artifact
                                        </Typography>
                                        <Select
                                            defaultValue={[]}
                                            maxMenuHeight={125}
                                            onChange={(option) => {
                                                setselectedtoArtifact(option.value);
                                            }}
                                            options={artifactList}
                                            isClearable
                                            isSearchable
                                            components={{ Option: IconOption }}
                                        />

                                    </div>
                                    <div className="form-buttons">
                                        <Button className="cancel" onClick={onClose}>Cancel</Button>
                                        <input type="submit" value="Save Conduit" data-testid="saveConduit" />
                                    </div>

                                </form>
                            </Container>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </>
    );


}
import { Button, Grid, Box, IconButton, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";

import { useMutation, useQuery } from "@apollo/client";
import { GLOBAL_OWNERS } from "../graphql/queryGlobalOwner";
import { AddGlobalOwnerModal } from "../pages/AddGlobalOwner";
import {REMOVE_GLOBALOWNER} from "../graphql/mutationRemoveGlobalOwner";
import CenteredProgress from "./CenteredProgress";

import {
    Delete as DeleteIcon,
  } from '@mui/icons-material';

  export default function GlobalUsers() {
    const[tableData, setTableData] = useState([]);
    const [addGlobalOwnerModalOpen, setAddGlobalOwnerModalOpen] = useState(false);
    const [removeGlobalOwner] = useMutation(REMOVE_GLOBALOWNER);

    const columns = [

        {
            accessorKey: "firstName",
            header: "First Name",
        },
        {
            accessorKey: "lastName",
            header: "Last Name"
        },
        {
            accessorKey: 'id',
            header: 'ID',
            enableHiding: true,
        }
    ];

    const { loading: loadingGlobalOwners, error: errorGlobalOwners, data: dataGlobalOwners, refetch: refetchGlobalOwners} = useQuery(GLOBAL_OWNERS);


    useEffect(() => {
        if(!tableData.length) {
            if (dataGlobalOwners) {
                const members = dataGlobalOwners.globalOwners.members.map((member) => {
                    return{
                        id: member._id,
                        firstName: member.firstName,
                        lastName: member.lastName,
                    };
                });
                setTableData(members);
            }
        }
    }, [dataGlobalOwners, tableData]);

    if(loadingGlobalOwners) {
        return <CenteredProgress />;
    }else if(errorGlobalOwners) {
        return <Alert severity="error">Oops! An error occured during the process</Alert>;
    }

    const handleCreateNewRow = async (values) => {
        setTableData([...tableData, values]);
    };

    const handleDeleteClick = async (row) => {
        try {
            const userId = row.original._id || row.original.id;
            await removeGlobalOwner({ variables: { userId } });
        
            await refetchGlobalOwners();
            const updatedTableData = tableData.filter((rowData) => rowData.id !== userId);
            setTableData(updatedTableData);
          } catch (error) {
            console.error(error);
          }
    };

    console.log(tableData)

    return (

        <Grid item xs={12} >
            <MaterialReactTable
                initialState={{columnVisibility: {id: false} }}
                data={tableData}
                enableRowActions
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                        <IconButton
                          color="primary"
                          title="Remove Global Owner"
                          onClick={() => {
                            handleDeleteClick(row);
                          }}
                          >
                            <DeleteIcon />
                          </IconButton>
                    </Box>
                )}
                columns={columns}
                positionActionsColumn="last"
                renderTopToolbarCustomActions={() => (
                    <Button 
                        onClick={() => setAddGlobalOwnerModalOpen(!addGlobalOwnerModalOpen)}
                        style={{
                            backgroundColor: "#1f576c",
                            color: "#fff",
                            float: "right",
                        }}
                        component="span"
                    >
                        Add Global Owner
                    </Button>
                )}
            />

            <AddGlobalOwnerModal
              open={addGlobalOwnerModalOpen}
              columns={columns}
              onClose={() => setAddGlobalOwnerModalOpen(!addGlobalOwnerModalOpen)}
              handleCreateNewRow={handleCreateNewRow}
            />
        </Grid>
    )
  }
import React from 'react';

function Footer() {
    return (
        <footer>
            {/* <a href="https://aedo.com"> Powered by Aedo.com</a> */}

        </footer>
    );
}

export default Footer;
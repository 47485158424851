import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { List } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StorageIcon from '@mui/icons-material/Storage';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import { useHistory } from 'react-router-dom';
import MainRouter from '../pages/MainRouter';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import RouterListItem from './RouterListItem';
import CenteredProgress from './CenteredProgress';
import errormessage from './ErrorMessage';
import CurrentUser from '../layout/CurrentUserProfile';
import GroupsIcon from '@mui/icons-material/Groups';
import { CURRENTUSER_ROLE } from '../graphql/queryCurrentUserRole'
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { GLOBAL_OWNERS } from '../graphql/queryGlobalOwner';
import SettingsIcon from '@mui/icons-material/Settings';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import AnalyticsIcon from '@mui/icons-material/Analytics';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft({ loading, error, tenant, availableTenants, currentUser }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isGlobalOwner, setIsGlobalOwner] = useState(false)
  const openmenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const { oktaAuth } = useOktaAuth();
  // const handleSignOut = (event) => {
  //   oktaAuth.signOut();
  // };

  let history = useHistory();
  const { data } = useQuery(GLOBAL_OWNERS);

  useEffect(() => {
    if (data) {
      const globalOwners = _.get(data, 'globalOwners.members', []);
      const isCurrentUserGlobalOwner = globalOwners.some(
        (member) =>
          member.firstName === currentUser.firstName &&
          member.lastName === currentUser.lastName
      );
      setIsGlobalOwner(isCurrentUserGlobalOwner);
    }
  }, [data, currentUser]);
  const { loading: loadingCurrentUser,
    data: currentuserData, } = useQuery(CURRENTUSER_ROLE, {
      variables: {
        tenantId: tenant ? tenant._id : '',
      },
    });
  const currentuserItems = _.get(currentuserData, "findCurrentUserInTenant", [])
  function checkCurrentUserRole(arr) {
    return arr.every((item) => item.role === 'GlobalOwner' || item.role === 'OWNER' || item.role === 'READER');
  }
  const hasCurrentUserAccess = currentuserItems.length > 0 ? checkCurrentUserRole(currentuserItems) : false;

  if (loading || loadingCurrentUser) {
    return <CenteredProgress />;
  }

  if (error) {
    console.log(error)
    return errormessage({
      title: 'Permission Needed',
      description: "You don't have access to data please contact support team.",
    });
  }

  const handleDrawerListItemClick = (e) => {
   
    if (e === 'Home') {
      history.push('/');
    }
    if (e === 'tenants') {
      history.push('/configs');
    }
    if (availableTenants.length === 1) {
      if (e === 'Data') {
        history.push(`/${availableTenants[0].name}/d`);
      } else if (e === 'Reports') {
        history.push(`/${availableTenants[0].name}/r`);
      } else if (e === 'Applications') {
        history.push(`/${availableTenants[0].name}/a`);
      } else if (e === 'Users') {
        history.push(`/${availableTenants[0].name}/u`);
      }
      else if (e === 'Teams') {
        history.push(`/${availableTenants[0].name}/t`);
      }
      else if (e === 'Conduits') {
        history.push(`/${availableTenants[0].name}/cl`);
      }
      else if(e==='Contact Us') {
        history.push(`/${availableTenants[0].name}/cu`)
      }
    } else {
      if (e === 'Data') {
        history.push(`/${tenant.name}/d`);
      } else if (e === 'Reports') {
        history.push(`/${tenant.name}/r`);
      } else if (e === 'Applications') {
        history.push(`/${tenant.name}/a`);
      } else if (e === 'Users') {
        history.push(`/${tenant.name}/u`);
      } else if (e === 'Teams') {
        history.push(`/${tenant.name}/t`);
      }
      else if (e === 'Conduits') {
        history.push(`/${tenant.name}/cl`);
      } else if ( e === 'Contact Us'){
        history.push(`/${tenant.name}/cu`);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: '#1F576C', color: '#fff' }}
      >
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Button
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, width: 64, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </Button>
            <Typography variant="h6">Aedo</Typography>
          </Box>

          <div>
            <Button
              id="tenant-positioned-button"
              aria-controls={open ? 'tenant-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              color={'inherit'}
              style={{
                border: '1px solid #fff',
                borderRadius: '15px',
                marginRight: '1rem',
              }}
            >
              <ManageAccountsIcon />
              {tenant ? tenant.displayName : null}
            </Button>
            <Menu
              id="tenant-positioned-menu"
              aria-labelledby="tenant-positioned-button"
              anchorEl={anchorEl}
              open={openmenu}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {availableTenants.map((t, idx) => (
                <div key={t._id} onClick={handleClose}>
                  <RouterListItem
                    to={`/${t.name}`}
                    displayName={t.displayName}
                    description={t.description}
                    icon={<CorporateFareIcon />}
                  />
                  {idx !== availableTenants.length - 1 ? <Divider /> : null}
                </div>
              ))}
            </Menu>
          </div>
          {currentUser ? <CurrentUser currentUser={currentUser} /> : null}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Button onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </Button>
        </DrawerHeader>
        <Divider />

        <List>
          {tenant
            ? hasCurrentUserAccess ? ['Home', 'Data', 'Reports', 'Applications', 'Users', 'Teams', 'Conduits', 'Contact Us'].map(
              (text, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    handleDrawerListItemClick(text);
                  }}
                >
                  <ListItemIcon>
                    {index === 0 ? <DashboardIcon /> : null}
                    {index === 1 ? <StorageIcon /> : null}
                    {index === 2 ? <AssessmentIcon /> : null}
                    {index === 3 ? <AppShortcutIcon /> : null}
                    {index === 4 ? <PersonIcon /> : null}
                    {index === 5 ? <GroupsIcon /> : null}
                    {index === 6 && isGlobalOwner? <AutoAwesomeMotionIcon /> : null}
                    {index === 7 ? <ContactSupportIcon /> : null}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))
              : ["Home", "Data", "Reports", "Applications", "Contact Us"].map(
                (text, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => {
                      handleDrawerListItemClick(text);
                    }}
                  >
                    <ListItemIcon>
                      {index === 0 ? <DashboardIcon /> : null}
                      {index === 1 ? <StorageIcon /> : null}
                      {index === 2 ? <AssessmentIcon /> : null}
                      {index === 3 ? <AppShortcutIcon /> : null}
                      {index === 4 ? <ContactSupportIcon /> : null}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                )
              )
            : ["Home"].map((text, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  handleDrawerListItemClick(text);
                }}
              >
                <ListItemIcon>
                  {index === 0 ? <DashboardIcon /> : null}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))
          }
        </List>

          

        {isGlobalOwner && (
          <>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <Divider />
              <ListItem
                onClick={() => history.push('/configs')}
                button
              >
                <SettingsIcon style={{ color: 'mediumseagreen' }} />
                <ListItemText style={{ marginLeft: '2rem' }} primary="Datahub Configs" />
              </ListItem>
            </div>
          </>
        )}


      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <MainRouter {...{ loading, error, tenant, currentUser, availableTenants }} />
      </Main>
    </Box>
    
  );
}
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import uploadFileToBlob from './azureBlob';
import _ from 'lodash';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  LinearProgress,
} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SimpleDateTime from 'react-simple-timestamp-to-date';
import Tooltip from '@mui/material/Tooltip';
import { ArtifactHeaderCard } from '../components/ArtifactHeaderCard';
import { ArtifactHealthTable } from '../components/ArtifactHealthTable';
import CenteredProgress from '../components/CenteredProgress';
import { ADD_FILEVERSION } from '../graphql/mutationAddFileversion';
import { FIND_ONE_FILEVERSION } from '../graphql/queryFileVersion';
import FileHistory from './FileHistory';
import { useQuery } from '@apollo/client';
import { ARTIFACT_CONNECTION_DETAILED } from '../graphql/queryArtifactConnection';
import MaterialReactTable from 'material-react-table';
import { DELETE_FILEVERSION } from '../graphql/mutationDeleteFileVersion';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import HistoryIcon from '@mui/icons-material/History';
import { useOktaAuth } from '@okta/okta-react';
import { STORE_USER_TRACKING_DATA } from '../graphql/mutationStoreUserTrackingData';


const FileDataset = ({ dataset, tenant, currentUser }) => {
  const [value, setValue] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [addFileversion] = useMutation(ADD_FILEVERSION);
  const [fileSlots, setFileSlots] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [removeFileversion] = useMutation(DELETE_FILEVERSION);
  const [files, setfiles] = useState([]);
  const {authState} = useOktaAuth();
  const [sendEvent] = useMutation(STORE_USER_TRACKING_DATA)
 
 
  const userName = currentUser.firstName
    ? currentUser.firstName + ' ' + currentUser.lastName
    : currentUser.email;
  const Userabilities = _.get(dataset, 'abilities', []);

  const userEmail = authState.idToken.claims.email

  const today = new Date(),
  // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds(),
  date = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();
  
  const { error: itemsError, data: itemsData } = useQuery(
    ARTIFACT_CONNECTION_DETAILED,
    {
      variables: {
        tenantId: tenant ? tenant._id : '',
        filter: {
          name: dataset.name,
        },
      },
    }
  );

  // const datasetDetail = _.get(itemsData, 'artifactConnection.edges', []).map(
  //   (e) => e.node
  // );
  // const fileslotList = _.get(datasetDetail[0], 'fileslots', []);
  // const files = fileslotList.map((f) => {
  //   return {
  //     id: f._id,
  //     name: f.name,
  //     fileName: f.fileName,
  //   };
  // });

  useEffect(() => {
    const fetchData = async () => {
      if (!Data.length) {
        setIsLoading(true);
        if (itemsData) {
          const datasetDetail = _.get(itemsData, 'artifactConnection.edges', []).map(
            (e) => e.node
          );
           const fileslotList = _.get(datasetDetail[0], 'fileslots', []);
           const fileItems = fileslotList.map((f) => {
            return {
              id: f._id,
              name: f.name,
              fileName: f.fileName,
            };
          });
          setFileSlots(fileslotList);
          setfiles(fileItems)
          setIsLoading(false);
        }
      } else {
        
      }
    }
    fetchData();

  }, [itemsData, Data.length]);

  // useEffect(() => {
  //   if (_.isNull(fileslotList.currentVersion)) {
  //     setIsLoading(false);
  //   }
  // }, [fileslotList]);

  const [getDownloadUrl, { error: downloadError }] = useLazyQuery(
    FIND_ONE_FILEVERSION,
    {
      onCompleted: (data) => {
        if (
          data &&
          data.findOneFileVersion &&
          data.findOneFileVersion.downloadUrl
        ) {
          const link = document.createElement('a');
          link.href = data.findOneFileVersion.downloadUrl;
          link.click();
        }
      },
    }
  );
  if (downloadError || itemsError) {
    return <CenteredProgress />;
  }
  const DownloadFileversion = (file) => {
    if (Userabilities.canDownload) {
      getDownloadUrl({
        variables: {
          input: {
            _id: file.fileversionId,
          },
        },
      });
      const trackingEvent = {
        file: file.fileversionName,
        createdAt: file.fileversioncreatedAt,
        source: file.fileversionSourse,
        dataset: dataset.name,
        userName: userName,
        userEmail:userEmail,
        action: "download",
        tenantName: tenant.displayName
      }
      sendTrackingEvent(trackingEvent);
    }
  };
  const sortVersions = (array) => {
    array.sort(function (a, b) {
      const sortitem =
        new Date(b.fileversioncreatedAt) - new Date(a.fileversioncreatedAt);
      return sortitem;
    });
  };
  const getfileversions = (fileslotslist) => {
    const versionslist = [];
    fileslotslist.forEach((element) => {
      if (element.currentVersion) {
        versionslist.push(element);
      }
    });
    const lastfileVersions = versionslist.map((f) => {
      return {
        fileslotId: f._id,
        fileslotName: f.name,
        fileversionId: f.currentVersion ? f.currentVersion._id : '',
        fileversionName: f.currentVersion ? f.currentVersion.name : '',
        fileversionUser: f.currentVersion ? f.currentVersion.user : '',
        fileversionSourse: f.currentVersion ? f.currentVersion.source : '',
        fileversionStatus: f.currentVersion ? f.currentVersion.status : '',
        fileversioncreatedAt: f.currentVersion
          ? f.currentVersion.createdAt
          : '',
        fileVersionDownloadUrl: f.currentVersion
          ? f.currentVersion.downloadUrl
          : '',
      };
    });
    if (versionslist.length !== 0) {
      sortVersions(lastfileVersions);
      setData(lastfileVersions);
      setSelectedRow(0);
      setSelectedRowData(lastfileVersions[0]);
      setSelectedItem(lastfileVersions[0]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return;
    }
  };

  const makeSelection = (e, id) => {
    // setFileSlots(fileslotList);
    const file = files.find((f) => f.id === id);
    if (file) {
      getfileversions(fileSlots);
      setSelectedFile(file);
      setSelectedRow(0);
    } else {
      setSelectedFile(null);
      setSelectedRow(null);
      setIsLoading(false);
    }
  };

  if (!selectedFile && files && files.length > 0) {
    makeSelection(null, _.get(files, '0.id', null));
  }

  const onUpdateFileVersion = async (e) => {
    const fileToUpload = e.target.files[0];

    setUpdating(true);
    try {
      const r = await addFileversion({
        variables: {
          fileslot: selectedRowData.fileslotId,
          name:
            selectedRowData.fileslotName +
            '.' +
            fileToUpload.name.split('.')[1],
          fileName: fileToUpload.name,
          status: 'completed',
          source: 'User',
          user: userName,
          tenantId: tenant._id,
        },
      });

      const newFileversion = _.get(r, 'data.createFileVersion');
      // *** UPLOAD TO AZURE STORAGE ***
      var uploadOnBlob = await uploadFileToBlob(newFileversion.uploadUrl, fileToUpload, userName);
      if (uploadOnBlob) {
        const updatedcurrentversion = {
          _id: newFileversion._id,
          name: newFileversion.name,
          source: newFileversion.source,
          status: newFileversion.status,
          user: newFileversion.user,
          fileName: newFileversion.fileName,
          createdAt: newFileversion.createdAt,
          date:date,
          downloadUrl: '',
        };
        const updatelastfileVersions = fileSlots.map((f) => {
          if (f._id === selectedRowData.fileslotId) {
            return { ...f, currentVersion: updatedcurrentversion };
          } else {
            return f;
          }
        });
        alert('File has been successfully uploaded.');
        const trackingEvent = {
          file: newFileversion.name,
          createdAt: newFileversion.createdAt,
          source: newFileversion.source,
          userName: userName,
          userEmail: userEmail,
          dataset: dataset.name,
          action: "Upload",
          tenantName: tenant.displayName
        }
        sendTrackingEvent(trackingEvent);
        setFileSlots(updatelastfileVersions);
        getfileversions(updatelastfileVersions);
      } else {
        await removeFileversion({
          variables: {
            FileVersionId: newFileversion._id,
            tenantId: tenant._id,
          },
        });
        alert('File cannot be upload.Please try again!');

      }

      setUploading(false);
    } catch (error) {
      alert(error);
      setUploading(false);
    }
  };

  const sendTrackingEvent = async (eventData) => {
    try {
      await sendEvent({
        variables: {
          input: {
            event_type: 'Download_Upload_History',
            event_data: eventData,
          },
        },
      });
    } catch (error) {
      console.log('Error tracking: ', error);
    }
  };

  const columns = [
    {
      header: 'File',
      accessorKey: 'fileslotName',
    },
    {
      header: 'User',
      accessorKey: 'fileversionUser',
    },
    {
      header: 'Last Update',
      accessorFn: (rowData) =>
        SimpleDateTime({
          dateSeparator: '-',
          format: 'YMD',
          timeSeparator: ':',
          showTime: '1',
          children: _.get(rowData, 'fileversioncreatedAt'),
        }),
    },
  ];

  return (
    <Container maxWidth="false" x={{ width: '100%' }}>
      <ArtifactHeaderCard
        breadcrumbs={[
          {
            displayName: tenant.displayName,
            path: `/${tenant.name}`,
          },
          {
            displayName: 'data',
            path: `/${tenant.name}/d`,
          },
          {
            displayName: dataset.displayName,
            path: `/${tenant.name}/d/${dataset.name}`,
          },
        ]}
        artifact={dataset}
        tabs={['Files', 'Health']}
        selectedIndex={value}
        onSelectionChange={handleChange}
      />

      <TabPanel value={value} index={0}>
        {uploading && updating && <LinearProgress />}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                sx={{ border: 1, borderColor: 'lightgrey', color: '#fff' }}
                action={
                  !_.isNull(selectedRow) &&
                    selectedRow !== undefined &&
                    Userabilities.canUpload &&
                    Data.length !== 0 ? (
                    <div>
                      <input
                        style={{ display: 'none' }}
                        id="raised-update-file"
                        type="file"
                        onChange={onUpdateFileVersion}
                      />
                      <label htmlFor="raised-update-file">
                        <IconButton
                          style={{
                            backgroundColor: '#91c8dd',
                            border: '0',
                            borderRadius: '5px',
                            margin: '5px',
                            padding: '10px',
                            height: '40px',
                          }}
                          component="span"
                        >
                          Update File
                          <FileUploadOutlinedIcon />
                        </IconButton>
                      </label>
                    </div>
                  ) : null
                }
                title="."
              ></CardHeader>
              <CardContent sx={{ my: 3 }}>
                <FileHistory
                  file={selectedItem}
                  tenant={tenant}
                  userAbility={Userabilities.canDownload}
                  showModal={showModal}
                  onClose={() => setShowModal(false)}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {isloading ? <CenteredProgress /> :
                      <MaterialReactTable
                        columns={columns}
                        data={Data}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableSorting={false}
                        enableBottomToolbar={false}
                        enableTopToolbar={false}
                        enableColumnActions={false}
                        enableRowActions
                        positionActionsColumn="last"
                        renderRowActions={({ cell, row, table }) => (
                          <Box>
                            <IconButton
                              onClick={() => {
                                setShowModal(!showModal);
                                setSelectedItem(row.original);
                              }}
                            > <Tooltip title="File Versions" arrow><HistoryIcon /></Tooltip></IconButton>

                            {Userabilities.canDownload ?
                              <IconButton onClick={() => {
                                DownloadFileversion(row.original);
                              }}
                              > <Tooltip title="Download Latest Version" arrow>
                                  <FileDownloadOutlinedIcon />
                                </Tooltip></IconButton> : null}


                            {/* {Userabilities.canUpload ?
                            <IconButton onClick={() => {
                              console.log("upload")
                            }}
                            >
                              <Tooltip title="Upload New File" arrow><AddFileIcon /></Tooltip>
                            </IconButton> : null} */}
                          </Box>
                        )}

                      />}

                    {/* <MaterialTable
                      icons={tableIcons}
                      localization={{
                        body: {
                          emptyDataSourceMessage: (''),
                        },
                      }}
                      options={{
                        headerStyle: {
                          backgroundColor: '#e8f2f5',
                          color: '#000',
                          fontSize: '16px',
                          border: 'none !important',
                          padding: '10px 15px',
                          fontWeight: 'bold'
                        },
                        actionsColumnIndex: -1,
                        padding: 'dense',
                        sorting: true,
                        toolbar: false,
                        pageSizeOptions: [10],
                        pageSize: 10,
                        columnsButton: false,
                        search: false,
                        rowStyle: (rowData) => ({
                          backgroundColor:
                            selectedRow === rowData.tableData.id ? "#e7e7e7" : "#FFF",
                        }),
                      }}
                      data={Data}
                      isLoading={isloading}
                      onChangePage
                      columns={columns}
                      onRowClick={(evt, selectedRow) => { setSelectedRow(selectedRow.tableData.id); setSelectedRowData(selectedRow); }}
                      actions={[
                        {
                          icon: () => Userabilities.canDownload ? <FileDownloadOutlinedIcon /> : '',
                          tooltip: "Download Latest Version",
                          onClick: (event, rowData) => {
                            DownloadFileversion(rowData)
                          }
                        },
                        {
                          icon: () => <HistoryIcon />,
                          tooltip: "Show File History",
                          onClick: (event, rowData) => {
                            setShowModal(!showModal);
                            setSelectedItem(rowData);
                            <FileHistory file={selectedItem} tenant={tenant} userability={Userabilities.canDownload} showModal={showModal} />
                          },
                        }
                      ]}
                    /> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <ArtifactHealthTable artifact={dataset}></ArtifactHealthTable>
      </TabPanel>
    </Container>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default FileDataset;
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/material";
import Crumbs from "../components/Crumbs";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "stageName", headerName: "Stage Name", width: 130 },
  {
    field: "lastUpdate",
    headerName: "Last Update",
    description: "Finish Time.",
    sortable: true,
    width: 160,
  },
  {
    field: "status",
    headerName: "Status",
    type: "boolean",
    width: 90,
  },
];

const rows = [
  { id: 1, stageName: "Data Received", lastUpdate: "Dec 20", status: true },
  { id: 2, stageName: "Data Loaded", lastUpdate: "Dec 30", status: true },
  { id: 3, stageName: "Data Refreshed", lastUpdate: "Jan 1", status: false },
];

export default function DataTable() {
  return (
    <Container>
      <Crumbs />
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        //checkboxSelection
      />
    </Container>
  );
}

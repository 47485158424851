import { useState } from "react";
import { useQuery } from "@apollo/client";
import { ARTIFACT_DEPENDENCIES } from "../graphql/queryArtifactDepencies";
import CenteredProgress from "../components/CenteredProgress";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import SimpleDateTime from "react-simple-timestamp-to-date";




export const ArtifactHealthTable = ({ artifact }) => {
  const [sortModel, setSortModel] = useState([
    {
      field: "startedAt",
      sort: "desc",
    },
  ]);
  const { loading, error, data } = useQuery(ARTIFACT_DEPENDENCIES, {
    variables: {
      artifactId: artifact && artifact._id,
    },
  });

  if (loading || error) {
    return <CenteredProgress />;
  }

  const columnsTable = [
    { field: "name", headerName: "Name", width: 500 },

    {
      field: "startedAt",
      headerName: "Started At",
      description: "Start Time.",
      sortable: true,
      width: 250,
    },
    {
      field: "successful",
      headerName: "Success?",
      type: "boolean",
      width: 90,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
  ];

  const rows = data.getArtifactDependencies.map((c) => {
    return {
      id: c._id,
      status: _.get(c, "lastConduitRun.status"),
      successful: _.get(c, "lastConduitRun.successful"),
      name: _.get(c, "name"),
      startedAt: SimpleDateTime({
        dateSeparator: "-",
        format: "YMD",
        timeSeparator: ":",
        showTime: "1",
        children: _.get(c, "lastConduitRun.startedAt"),
      }),
    };
  });

  // startedAt: _.get(c, "lastConduitRun.startedAt"),

  return (
    <div style={{ height: 600, width: "100%" }}>
      
      <DataGrid
        density="compact"
        rows={rows}
        columns={columnsTable}
        pageSize={20}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        //checkboxSelection
      />
    </div>
  );
};

import gql from "graphql-tag";

export const ADD_MANY_USERS_TO_TENANT = gql`
  mutation AddManyUsersToTenant(
    $role: String!
    $tenantId: String!
    $userIdList: [String!]!
  ) {
    addManyUsersToTenant(
      role: $role
      tenantId: $tenantId
      userIdList: $userIdList
    ) {
      displayName
      tenantUsers {
        user {
          _id
          firstName
          lastName
          email
        }
        role
      }
    }
  }
`;

import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import Welcome from "./pages/Welcome";
import Authenticated from "./pages/Authenticated";
import Footer from './components/Footer';

const oktaAuth = new OktaAuth({
  issuer: "https://auth.aedo.com/oauth2/aus1blgkbftwewJWt357",
  clientId: "0oa1bld6dwmC8xRRY357",
  pkce: true,
  redirectUri: window.location.origin + "/implicit/callback",
  postLogoutRedirectUri: window.location.origin + "/welcome",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#6099B0",
      light: "#EDF8FD",
    },
    secondary: {
      main: "#E57E3E",
      light: "#EFAB80",
    },
  },
});

function Main() {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/welcome" component={Welcome} />
        <Route path="/implicit/callback">
          <LoginCallback errorComponent={ImplicitCallbackHandler} />
        </Route>
        <Route component={Authenticated} />
      </Switch>
    </Security>
  );
}

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <Main />
        </Router>
        <div>
          <Footer />
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}

const ImplicitCallbackHandler = ({ error }) => {
  console.error({ error });
  return <Redirect to="/welcome" />;
};

export default App;

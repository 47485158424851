import gql from 'graphql-tag';

export const CREATE_USER = gql`
mutation CreateUser($input: CreateUserInput!, $role: String!, $tenantId: String!) {
  createUser(input: $input, role: $role, tenantId: $tenantId) {
    _id
    lastName
    firstName
    email
  }
}
`;


import { gql, useQuery } from '@apollo/client';
import { Container, Grid } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { Canvas, Edge, Label, MarkerArrow, Node, Port } from 'reaflow';
import { ArtifactIcon } from '../components/ArtifactIcon';
import CenteredProgress from '../components/CenteredProgress';
import Crumbs from '../components/Crumbs';

const Conduits = ({ tenant }) => {
  const { loading, data } = useQuery(
    gql`
      query ListArtifacts($tenantId: String) {
        listArtifacts(tenantId: $tenantId) {
          ... on App {
            __typename
            _id
            name
            fromConduits {
              _id
              name
            }
            toConduits {
              _id
              name
            }
          }
          ... on EmbeddedApp {
            __typename
            _id
            name
            fromConduits {
              _id
              name
            }
            toConduits {
              _id
              name
            }
          }
          ... on FileDataset {
            __typename
            _id
            name
            fromConduits {
              _id
              name
            }
            toConduits {
              _id
              name
            }
          }
          ... on TableDataset {
            __typename
            _id
            name
            fromConduits {
              _id
              name
            }
            toConduits {
              _id
              name
            }
          }
          ... on Report {
            __typename
            _id
            name
            fromConduits {
              _id
              name
            }
            toConduits {
              _id
              name
            }
          }
        }
      }
    `,
    { variables: { tenantId: tenant._id } }
  );

  if (loading) {
    return <CenteredProgress />;
  }

  const nodes = [];
  const edgesMap = {};

  for (const artifact of data?.listArtifacts) {
    nodes.push({
      id: artifact._id,
      text: artifact.name,
      data: artifact,
    });
    for (const from of artifact.fromConduits) {
      let edge = _.get(edgesMap, from._id, { id: from._id, name: from.name });
      _.set(edge, 'to', artifact._id);
      _.set(edgesMap, edge.id, edge);
    }
    for (const to of artifact.toConduits) {
      let edge = _.get(edgesMap, to._id, { id: to._id, name: to.name });
      _.set(edge, 'from', artifact._id);
      _.set(edgesMap, edge.id, edge);
    }
  }

  const edges = _.values(edgesMap).filter(
    (e) => !_.isEmpty(e.id) && !_.isEmpty(e.from) && !_.isEmpty(e.to)
  );

  return (
    <Container maxWidth="false">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Crumbs
            parts={[
              {
                displayName: tenant.displayName,
                path: `/${tenant.name}`,
              },
              {
                displayName: 'conduits',
                path: `/${tenant.name}/c`,
              },
            ]}
          />
        </Grid>
        <Canvas
          nodes={nodes}
          edges={edges}
          direction={'LEFT'}
          fit={true}
          node={(n) => (
            // something like this can be used to make a custom Node
            // <foreignObject width={'100%'}>
            //   <Box
            //     sx={{
            //       boxShadow: 3,
            //       borderRadius: 1,
            //       p: 2,
            //       minWidth: 300,
            //       flexDirection: 'row',
            //       alignItems:'center',
            //       display: 'inline-flex'
            //     }}
            //   >
            //     <ArtifactIcon item={n.properties.data} />
            //     <Typography>{n.properties.text}</Typography>
            //   </Box>
            // </foreignObject>
            <Node
              style={{ stroke: '#1a192b', fill: 'white', strokeWidth: 1 }}
              label={<Label style={{ fill: 'black' }} />}
              port={
                <Port
                  style={{ fill: 'blue', stroke: 'white' }}
                  rx={10}
                  ry={10}
                />
              }
              icon={<ArtifactIcon item={n} size={16} />}
            />
          )}
          arrow={<MarkerArrow style={{ fill: '#b1b1b7' }} />}
          edge={<Edge className="edge" />}
        />
      </Grid>
    </Container>
  );
};

export default Conduits;

import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import * as React from 'react';
import ArtifactList from '../components/ArtifactList';
import Crumbs from '../components/Crumbs';

export default function DashboardContainer({ tenant }) {
  return (
    <Container maxWidth="false">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Crumbs
            parts={[
              {
                displayName: tenant.displayName,
                path: `/${tenant.name}`,
              },
              {
                displayName: 'reports',
                path: `/${tenant.name}/r`,
              },
            ]}
          />
        </Grid>
        <ArtifactList
          tenant={tenant}
          artifactTypes={['Report']}
          itemName={'Reports'}
        />
      </Grid>
    </Container>
  );
}

import { useOktaAuth } from '@okta/okta-react';
import React, { useState } from 'react';
import { Container, IconButton, Alert } from '@mui/material';
import Crumbs from './Crumbs';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { STORE_USER_TRACKING_DATA } from '../graphql/mutationStoreUserTrackingData';
import { useMutation } from '@apollo/client';


export const phoneNumberAutoFormat = (phoneNumber) => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 6) return number.replace(/(\d{3})(\d{1})/, "($1) $2");
  if (number.length < 9) return number.replace(/(\d{3})(\d{3})(\d{1})/, "($1) $2-$3");
  return number.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

const ContactUs = ({ tenant: propTenant, currentUser }) => {

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [sendloading, setSendLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [phonenumber, setPhoneNumber] = useState("");
  const [sendEvent] = useMutation(STORE_USER_TRACKING_DATA)
  const tenant = propTenant.displayName;
  const { authState } = useOktaAuth();
  const userEmail = currentUser.email;

  const eventData = {
    userName: currentUser.firstName + ' ' + currentUser.lastName,
    userEmail: userEmail,
    itemName: "Contact_Us",
    type: "form",
    tenantName: (propTenant.displayName !== undefined && propTenant.displayName !== 'NULL') ? propTenant.displayName : "",
    tenantId: propTenant._id,
    emailStatus: ""
  }

  const sendTrackingEvent = async (eventData) => {
    try {
      await sendEvent({
        variables: {
          input: {
            event_type: 'Contact_Us',
            event_data: eventData,
          },
        },
      });

    } catch (error) {
      console.log('Error tracking: ', error);
    }
  };
  const handleSubmit = async (e) => {

    e.preventDefault();
    try {
      var tenant = e.target[0].value;
      var userFirstName = e.target[1].value;
      var userLastName = e.target[2].value;
      var userPhone = e.target[3].value;
      var category = e.target[4].value;
      var message = e.target[5].value;


      setSendLoading(true)
      const serverUrl = process.env.REACT_APP_API_URL ?? "";
      const response = await fetch(`${serverUrl}/api/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + authState.accessToken.accessToken,
        },

        body: JSON.stringify({
          tenant,
          userFirstName,
          userLastName,
          userEmail,
          userPhone,
          category,
          message,

        }),
      });

      const contentType = response.headers.get('Content-Type');
      let responseData;

      if (contentType && contentType.includes('application/json')) {

        responseData = await response.json();
      } else {
        responseData = await response.text();
      }

      if (response.ok) {
        console.log('Email sent successfully');
        eventData.emailStatus = "success"
        setSendLoading(false)
        setFormSubmitted(true)

      } else {
        setSendLoading(false)
        setFormError(true)
        eventData.emailStatus = "error"
        console.error('Error sending email:', responseData.error);
      }
    } catch (error) {
      setSendLoading(false)
      setFormSubmitted(true)
      setFormError(true)
      eventData.emailStatus = "error"
      console.error('Error sending email:', error.message);
    }
    sendTrackingEvent(eventData)
  };

  const handleChange = async (e) => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    setPhoneNumber(targetValue)
  }

  return (

    <Container maxWidth={false} >
      <Crumbs
        parts={[
          {
            displayName: propTenant.displayName,
            path: `/${propTenant.name}`,
          },
          {
            displayName: 'Contact Us',
            path: `/${propTenant.name}/cu`,
          },
        ]}
      />
      <div className="contact-us-page" >
        <h1>Contact Us</h1>
        <p>
          If you have any questions, concerns,
          or feedback, please feel free to reach out to us using the form
          below. We'll get back to you as soon as possible.
        </p>
        <div className="contact-us-form" max-width="600px" margin="auto">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="tenant">Organization:</label>
              <input type="text" id="tenant" value={tenant} disabled />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                placeholder="Enter your first name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                placeholder="Enter your last name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                maxLength={13}
                placeholder="(xxx) xxx-xxxx"
                value={phonenumber}
                onChange={handleChange}
                required
              />

            </div>
            <div className="form-group">
              <label htmlFor="category">Category:</label>
              <select
                id="category"
                required
              >
                <option value="">Select Category</option>
                <option value="Data Issue">Data Issue</option>
                <option value="UI Issue">UI Issue</option>
                <option value="Access Issue">Access Issue</option>
                <option value="Feature Request">Feature Request</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                rows="6"
                cols="40"
                placeholder="*** Please provide a brief details of the issue ***"
                required
              ></textarea>
            </div>
            <div className="form-group">
              <button type="submit" id='contactSubmit'>Submit</button>
            </div>
          </form>
          {sendloading && <LinearProgress />}
          {formSubmitted && (<Alert action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setFormSubmitted(false);

              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="success" sx={{ width: '100%', backgroundColor: '#edf7ed !important' }}>
            Your message has been sent successfully, We will get back to you by maximum 2 business days.
          </Alert>)}
          {formError && <Alert action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setFormSubmitted(false);

              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          } severity="error" sx={{ width: '100%', backgroundColor: '#fdeded !important' }}>
            Something went wrong! Please contact Aedo support team at support@aedo.com.
          </Alert>
          }


        </div>
      </div>

    </Container>
  );
};

export default ContactUs;
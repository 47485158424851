import { useMutation } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { CREATE_TENANT } from "../graphql/mutationCreateTenant";

export const CreateNewTenantModal = ({
  open,
  columns,
  onClose,
  handleCreateNewRow,
}) => {
  const [createTenant] = useMutation(CREATE_TENANT);

  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      if (column.accessorKey !== "id") acc[column.accessorKey ?? ""] = "";
      return acc;
    }, {})
  );

  const handleSubmit = async (e) => {
    try {
      const res = await createTenant({
        variables: {
          input: {
            displayName: values.displayName,
            name: values.name,
            domain: values.domain,
            kvUri: values.kvUri
          },
        },
      });
      const newTenant = _.get(res, "data.createTenant");
      const newRow = {
        id: _.get(newTenant, "_id"),
        displayName: _.get(newTenant, "displayName"),
        domain: _.get(newTenant, "domain"),
        kvUri: _.get(newTenant, "kvUri"),
        name: _.get(newTenant, "name")
      }
      handleCreateNewRow(newRow);
    } catch (error) {
      alert(error);
    }
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle
        textAlign="center"
        style={{
          padding: "10px",
          color: "#fff",
          backgroundColor: "#1f576c",
          marginBottom: "15px",
        }}
      >
        Create New Tenant
      </DialogTitle>
      <DialogContent>
        <form handleSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              p: 1,
              width: "100%",
              minWidth: { xs: "300px", mt: "400px", pt: "400px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column) => column.header !== "ID" && (
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }} className="form-buttons">
        <Button onClick={onClose} className="cancel">
          Cancel
        </Button>
        <Button color="success" onClick={handleSubmit} className="saveuser">
          Create Tenant
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { withOktaAuth } from "@okta/okta-react";

const withAuthStub = (Component) => {
  Component.defaultProps = {
    ...Component.defaultProps,
    authState: {
      isAuthenticated: true,
      isPending: false,
      accessToken: window.Cypress.env("dbUserId"),
    },
  };

  return Component;
};

let withAuthForExport = withOktaAuth;

if (window.Cypress) {
  withAuthForExport = withAuthStub;
}

export default withAuthForExport;

import gql from 'graphql-tag';

export const ADD_USERS_PERMISSION = gql`
mutation UpdateManyEmbeddedAppPermissions($embeddedAppId: [ID!]!, $input: EmbeddedAppAbilitiesInput!, $teamId: ID!, $tenantId: ID!) {
    updateManyEmbeddedAppPermissions(embeddedAppId: $embeddedAppId, input: $input, teamId: $teamId, tenantId: $tenantId) {
        _id
        abilities {
          canUploadToFileDatasets
          canDownloadFromFileDatasets
        }
        displayName
        members {
          _id
          email
          firstName
          lastName
        }
        name
        teampermissions
    }
  }
`;

export const UPDATE_REPORTS_PERMISSION = gql`
mutation UpdateManyReportsPermissions($input: ReportsAbilitiesInput!, $reportId: [ID!]!, $teamId: ID!, $tenantId: ID!) {
  updateManyReportsPermissions(input: $input, reportId: $reportId, teamId: $teamId, tenantId: $tenantId) {
        _id
        abilities {
          canUploadToFileDatasets
          canDownloadFromFileDatasets
        }
        displayName
        members {
          _id
          email
          firstName
          lastName
        }
        name
        teampermissions
    }
  }
`;

import gql from 'graphql-tag';
export const REMOVE_TEAM = gql`
mutation DeleteTeam($teamId: String!) {
    deleteTeam(teamId: $teamId) {
      _id
      name
      displayName
     
    }
  }

`;
import React, { useEffect, useRef } from 'react';
import {
  IconButton,
  Box,
  Grid,
  Typography,
  Modal,
  Backdrop,
  Fade, Stack
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FILTER_FILEVERSION } from '../graphql/queryAllFileVersions';
import SimpleDateTime from 'react-simple-timestamp-to-date';
import { Alert } from '@mui/material';
import { Close } from '@mui/icons-material';
import withAuth from '../layout/withAuthChooser';
import MaterialReactTable from 'material-react-table';
import { useQuery } from '@apollo/client';
import CenteredProgress from '../components/CenteredProgress';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: '#fff',
  boxShadow: 24,
  borderRadius: "5px",
  padding: '15px !important',
};

const DownloadFileversion = ({ file }) => {
  if (!file) {
    return null;
  }
  const downloadFile = () => {
    if (file.downloadUrl !== '') {
      const link = document.createElement('a');
      link.href = file.downloadUrl;
      document.body.appendChild(link);
      link.click();
    } else {
      <Alert severity="info">There is no file to download!</Alert>;
      alert('There is no file to download!');
    }
  };
  return (
    <IconButton aria-label="download" onClick={downloadFile}>
      <FileDownloadOutlinedIcon />
    </IconButton>
  );
};

const FileHistory = ({
  file,
  tenant,
  userAbility,
  showModal,
  authState,
  authService,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  const tableRef = useRef();

  useEffect(() => {
    if (typeof tableRef?.current?.onQueryChange === 'function') {
      tableRef.current.onQueryChange();
    }
  }, []);

  const sortVersions = (array) => {
    array.sort(function (a, b) {
      const sortitem = new Date(b.createdAt) - new Date(a.createdAt);
      return sortitem;
    });
  };

  // const {
  //   error: allVersionsError,
  //   loading: allVersionsLoading,
  //   data: allVersionsData,
  // } = useQuery(FILTER_FILEVERSION, {
  //   variables: {
  //     tenantId: tenant ? tenant._id : "",
  //     filter: {
  //       fileslot: selectedFile ? selectedFile.fileslotId : "",
  //     },
  //   },
  // });

  // if (allVersionsError || allVersionsLoading) {
  //   <CenteredProgress />
  // }
  // const edges = _.get(allVersionsData, "fileversionfilter.edges", []);
  // items = edges.map((e) => e.node);
  // sortVersions(items);

  const { data, loading, error } = useQuery(FILTER_FILEVERSION, {
    variables: {
      after: String(0 /*query.page * query.pageSize*/),
      // first: query.pageSize,
      tenantId: tenant ? tenant._id : '',
      filter: {
        fileslot: file ? file.fileslotId : '',
      },
    },
    skip: !showModal,
  });

  if (error) {
    return <CenteredProgress />
  }

  const result = data?.fileversionfilter?.edges.map((e) => e.node) || [];
  sortVersions(result);

  //   return {
  //     data: result,
  //     page: query.page,
  //     totalCount: res?.data?.fileversionfilter?.pageInfo.totalDocs,
  //   };
  // });
  const columns = [
    {
      header: 'File',
      accessorKey: 'name',
    },
    {
      header: 'User',
      accessorKey: 'user',
    },
    {
      header: 'Date',
      accessorFn: (rowData) =>
        SimpleDateTime({
          dateSeparator: '-',
          format: 'YMD',
          timeSeparator: ':',
          showTime: '1',
          children: rowData.createdAt,
        }),
    },
  ];

  return (
    <Modal
      aria-labelledby="modal-title"
      open={showModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        {loading ?
          <Stack

            direction="row-reverse"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}

          >  <CenteredProgress /></Stack>
          :
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  style={{ padding: '0 20px 20px' }}
                >
                  Version History
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ padding: '5px', textAlign: 'right' }}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item >


              <MaterialReactTable sx={style}
                columns={columns}
                data={result}
                enableColumnFilters={false}
                enablePagination
                enableSorting={false}
                enableBottomToolbar
                enableTopToolbar={false}
                enableRowActions
                enableColumnActions={false}
                enableGlobalFilter={false}
                initialState={{ pagination: { pageSize: 5 } }}
                positionActionsColumn="last"
                state={{
                  loading
                }}
                renderRowActions={({ cell, row, table }) => {
                  if (userAbility) {
                    return <DownloadFileversion file={row.original} />;
                  }
                  return null;
                }}
              />




            </Grid>
          </Box>}
      </Fade>
    </Modal>
  );
};
export default withAuth(FileHistory);

import gql from 'graphql-tag';

export const FILTER_FILEVERSION = gql`
query Fileversionfilter($after: String, $first: Int, $tenantId: String ,$filter: FileversionFilterInput ) {
  fileversionfilter(after: $after,first: $first,tenantId: $tenantId,filter: $filter) {
    edges {
      cursor
      node {
         _id
         fileslot {
           _id
         }
         name
        source
        user
        status
        createdAt
        downloadUrl
      }
    }
    pageInfo {
      totalDocs
      startCursor
    }
  }
}
`;

import gql from 'graphql-tag';

export const ARTIFACT_DEPENDENCIES = gql`
  query artifactDependencies($artifactId: String!) {
    getArtifactDependencies(artifactId: $artifactId) {
      _id
      name
      lastConduitRun {
        status
        startedAt
        successful
      }
      dependencyLevel
    }
  }
`;

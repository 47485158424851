import React from "react";

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';


export default function notfoundmessage(props) {

  return (

    <Container style={{ backgroundColor: "#fff", padding: 50 }}>
      <Alert severity="info" style={{ position: "center", backgroundColor: "#e0f7fd" }}>There are no available {props.item}.</Alert>
    </Container>
  );

}
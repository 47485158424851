import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import withAuth from './withAuthChooser'
import { useOktaAuth } from '@okta/okta-react';


const AuthButton = ({ authState, authService, tcolor, bgcolor }) => {
  const [isSignedIn, setIsSignedIn] = useState(null)

  const { oktaAuth } = useOktaAuth();


  useEffect(() => {
    if (authState.isAuthenticated) {
      setIsSignedIn(authState.isAuthenticated)
    }
  }, [authState])

  if (isSignedIn === null) return ''

  const login = () =>   oktaAuth.signIn();

  const logout = () => oktaAuth.signOut();

  return (
    <Button
      style={{ color: ` ${tcolor ? tcolor : '#E57E3E'} `, backgroundColor: ` ${bgcolor ? bgcolor : null} ` , margin:'0 auto'}}
      onClick={
        (isSignedIn ? logout : login)
      }
    >
      {isSignedIn ? 'SIGN OUT' : 'SIGN IN'}
    </Button>
  )
}

export default withAuth(AuthButton);
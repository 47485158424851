import {
    ApolloClient, ApolloProvider, createHttpLink,
    InMemoryCache
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React from "react";
import DashboardContainer from "../components/Dashboard";
import withAuth from "./withAuthChooser";

const ApolloContainer = ({ authState, authService }) => {
    
    const newClient = () => {
        const basUrl = process.env.REACT_APP_API_URL ?? '';
        const httpLink = createHttpLink({
            uri: `${basUrl}/api/v1/graphql`,
        });

        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    //'x-aedo-api-key': authState.accessToken.accessToken,
                    Authorization: "Bearer " + authState.accessToken.accessToken,
                },
            };
        });

        const client = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
        });
        return client;
    };


    return (
        <ApolloProvider client={newClient()}>
            <DashboardContainer />
        </ApolloProvider>
    );
};

export default withAuth(ApolloContainer);

import React from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import CenteredProgress from '../components/CenteredProgress';

import FileDataset from './FileDataset';
import TableDataset from './TableDataset';
import { ARTIFACT_CONNECTION_WITH_DESCRIPTION } from '../graphql/queryArtifactConnection';

const Dataset = ({ tenant, currentUser }) => {
  const params = useParams();
  const {
    loading: itemsLoading,
    error: itemsError,
    data: itemsData,
  } = useQuery(ARTIFACT_CONNECTION_WITH_DESCRIPTION, {
    variables: {
      tenantId: tenant ? tenant._id : '',
      filter: {
        name: params.dname,
      },
    },
    skip: !tenant,
  });

  const currentTenant = tenant;

  if (itemsLoading || itemsError || !tenant || !currentUser) {
    return <CenteredProgress />;
  }

  const dataset = itemsData.artifactConnection.edges
    .map((e) => e.node)
    .find((d) => d.name === params.dname);

  if (dataset.__typename === 'FileDataset') {
    return (
      <FileDataset
        dataset={dataset}
        currentUser={currentUser}
        tenant={currentTenant}
      />
    );
  } else {
    return (
      <TableDataset
        dataset={dataset}
        currentUser={currentUser}
        tenant={currentTenant}
      />
    );
  }
};

export default Dataset;

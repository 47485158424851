import { useMutation, useQuery }  from "@apollo/client";
import {
    Dialog,
    DialogTitle,
    Stack,
    TextField,
    DialogActions,
    DialogContent,
    Button,
    FormControl,
    Autocomplete
  } from "@mui/material";
  import _ from "lodash";
  import React, { useState } from "react";
  import {CREATE_EMBEDDEDAPP} from "../graphql/mutationCreateArtifact"
  import { QUERY_TENANTS } from "../graphql/querySession";
  import {LIST_EMBEDDEDAPPS } from "../graphql/queryEmbeddedApp";
  import CenteredProgress from '../components/CenteredProgress';

  export const CreateNewEmbeddedAppModal = ({
    open,
    columns,
    inputColumns,
    onClose,
    handleCreateNewRow,
  }) => {
    const [createEmbbeddedApp] = useMutation(CREATE_EMBEDDEDAPP);
    const {loading:loadingTenants, data: tenantData, refetch: refetchTenants} = useQuery(QUERY_TENANTS);
    const {refetch: refetchEmbeddedApps} = useQuery(LIST_EMBEDDEDAPPS)
    

    const[values, setValues] = useState(() => 
        columns.reduce((acc, column) => {
            if(column.accessorKey !== "id") acc[column.accessorKey ?? ""] = "";
            return acc;
        }, {})    
    );

    const [selectedTenant, setSelectedTenant] = useState(null);

    const handleSubmit = async (e) => {
        try {
            const res  = await createEmbbeddedApp({
                variables: {
                    input: {
                        displayName: values.displayName,
                        name: values.name,
                        url: values.url
                    },
                    tenantId: selectedTenant._id,
                },
            });

            const NewEmbeddedApp = _.get(res, "data.createEmbeddedApp");
            const newRow = {
                id: _.get(NewEmbeddedApp, "_id"),
                displayName: _.get(NewEmbeddedApp, "displayName"),
                name: _.get(NewEmbeddedApp, "name"),
                url: _.get(NewEmbeddedApp, "url"),
                tenantId: selectedTenant._id,
                tenantName: _.get(NewEmbeddedApp, "tenant.displayName")
            }
            console.log(_.get(NewEmbeddedApp, "_id"));
            handleCreateNewRow(newRow);

            setSelectedTenant(null);
            refetchEmbeddedApps();
            refetchTenants();
        } catch (error) {
            alert(error);
        }
        onClose();
    };
    
    if (loadingTenants ) {
        return <CenteredProgress />;
    }
    return (
        <Dialog open= {open}>
            <DialogTitle
            textAlign="center"
            style={{
                padding: "10px",
                color: "#fff",
                backgroundColor: "#1f576c",
                marginBottom: "15px",
            }}
          >
            Create New Embedded App
            </DialogTitle>
            <DialogContent>
                <form handleSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            p: 1,
                            width: "100%",
                            minWidth: { xs: "300px", mt: "400px", pt: "400px" },
                            gap: "1.5rem",
                        }}
                    >
                        {inputColumns.map((column) => column.header !== "ID" && (
                            <TextField
                            key={column.accessorKey}
                            label={column.header}
                            name={column.accessorKey}
                            onChange={(e) =>
                              setValues({ ...values, [e.target.name]: e.target.value })
                            }
                          />
                        ))}
                        <FormControl>
                            <Autocomplete
                                options={tenantData.listTenants}
                                getOptionLabel={(tenant) => tenant.displayName}
                                value={selectedTenant}
                                onChange={(e, newValue) => setSelectedTenant(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Select Tenant"
                                    variant="outlined"
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                </form>
                </DialogContent>  
                <DialogActions sx={{ p: "1.25rem" }} className="form-buttons">
                    <Button onClick={onClose} className="cancel">
                        Cancel 
                    </Button>
                    <Button color="success" onClick={handleSubmit} className="saveuser">
                        Create Embedded App
                    </Button>
                </DialogActions>
        </Dialog>
    )
  }
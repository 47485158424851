import { Avatar, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import AuthButton from './LogoutButton';

const UserAvatar = styled(Avatar)`
background-color: #fff !important;
color: #1f576c !important;
border: 1px solid #606060;

`;

const StyledMenuItem = styled(MenuItem)`
  &:hover {
    background-color: transparent !important;
  }
`;

const CurrentUser = ({ currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>

      <IconButton
        aria-label="account Setting"
        aria-controls={open ? 'menu-appbar' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="small"
      >
        {(currentUser.firstName && currentUser.lastName) && (<UserAvatar>{currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)}</UserAvatar>)}
        {(!currentUser.firstName && currentUser.lastName) && (<UserAvatar>{currentUser.lastName.charAt(0)}</UserAvatar>)}
        {(currentUser.firstName && !currentUser.lastName) && (<UserAvatar>{currentUser.firstName.charAt(0)}</UserAvatar>)}
        {(!currentUser.firstName && !currentUser.lastName) && (<UserAvatar>{currentUser?.email?.charAt(0)}</UserAvatar>)}
      </IconButton>


      <Menu
        id="menu-appbar"
        style={{ textAlign: 'center' }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem > {currentUser.firstName ? (currentUser.firstName + ' ' + currentUser.lastName) : currentUser.email} </StyledMenuItem>
        <Divider />
        <StyledMenuItem ><AuthButton /></StyledMenuItem>

      </Menu>
    </div>
  )
}

export default CurrentUser

import gql from "graphql-tag";

export const LIST_EMBEDDEDAPPS = gql`

query ListEmbeddedApps($tenantId: String) {
  listEmbeddedApps(tenantId: $tenantId) {
    _id
    description
    displayName
    name
    url
  }
}
`;


export const LIST_EMBEDDEDAPPS_WITH_TENANT=gql`

query ListEmbeddedApps($tenantId: String) {
  listEmbeddedApps(tenantId: $tenantId) {
    _id
    description
    displayName
    name
    url
    tenant {
      name
      _id
    }
  }
}
`;


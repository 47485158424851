import gql from 'graphql-tag';

export const CREATE_EMBEDDEDAPP = gql`
mutation CreateArtifact($tenantId: String!, $input: CreateEmbeddedAppInput!) {
  createEmbeddedApp(tenantId: $tenantId, input: $input) {
    _id
    description
    displayName
    name
    url
    tenant {
      displayName
    }
  }
}
`;
import gql from 'graphql-tag';

export const FIND_ONE_FILEVERSION = gql`
query FindOneFileVersion($input: ListFileVersion) {
    findOneFileVersion(input: $input) {
    _id
    name
    downloadUrl  
    uploadUrl
    createdAt
    }
  }
`;

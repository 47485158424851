import { useMutation, useQuery } from "@apollo/client";
import {
    Dialog,
    DialogTitle,
    Stack,
    DialogActions,
    DialogContent,
    Button,
    Autocomplete, 
    TextField
} from "@mui/material";
import React, { useState } from "react";
import { ADD_GLOBALOWNER } from "../graphql/mutationAddGlobalOwner";
import { LIST_ALL_USERS } from "../graphql/queryListUsers";
import { GLOBAL_OWNERS } from "../graphql/queryGlobalOwner";

export const AddGlobalOwnerModal = ({
    open,
    columns,
    onClose,
    handleCreateNewRow,
}) => {
    const [addGlobalOwner] = useMutation(ADD_GLOBALOWNER);
    const { data: userData} = useQuery(LIST_ALL_USERS);
    const { data: dataGlobalOwners } = useQuery(GLOBAL_OWNERS);

    const filteredUsers = userData?.listUsers?.filter((user) => {
        return !dataGlobalOwners?.globalOwners.members.some((globalOwner) => globalOwner._id === user._id);
    });

    // const[values, setValues] = useState(() => 
    //     columns.reduce((acc, column) => {
    //         if(column.accessorKey !== "id") acc[column.accessorKey ?? ""] = "";
    //         return acc;
    //     }, {})
    // );

    const [selectedUser, setSelectedUser] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          if (!selectedUser) {
            alert("Please select a user.");
            return;
          }
          const userId = selectedUser._id;
      
          await addGlobalOwner({
            variables: {
              userId: userId,
            },
          });
          const addedUser = userData.listUsers.find((user) => user._id === userId);
      
          const newRow = {
            id: addedUser._id,
            firstName: addedUser.firstName,
            lastName: addedUser.lastName,
          };
          handleCreateNewRow(newRow);
      
          setSelectedUser(null);
        //   setValues((prevState) =>
        //     Object.keys(prevState).reduce((acc, key) => {
        //       acc[key] = "";
        //       return acc;
        //     }, {})
        //   );
        } catch (error) {
          alert(error.message);
        }
        onClose();
    };

    return(
        <Dialog open= {open}>
            <DialogTitle
            textAlign="center"
            style={{
                padding: "10px",
                color: "#fff",
                backgroundColor: "#1f576c",
                marginBottom: "15px",
            }} >
                Add Global Owner
            </DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            p: 1,
                            width: "100%",
                            minWidth: { xs: "300px", mt: "400px", pt: "400px" },
                            gap: "1.5rem",  
                        }}
                    >
                        <Autocomplete
                            options={filteredUsers}
                            getOptionLabel={(user) => user.firstName + " " + user.lastName}
                            value={selectedUser}
                            onChange={(e, newValue) => setSelectedUser(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select User"
                                    variant="outlined"
                                />
                            )}
                        />
                        
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: "1.25rem" }} className="form-buttons">
                    <Button onClick={onClose} className="cancel">
                        Cancel 
                    </Button>
                    <Button color="success" onClick={handleSubmit} className="saveuser">
                        Add
                    </Button>
                </DialogActions>
        </Dialog>
    );
}
import { useTheme } from '@mui/material/styles';
import { BsFiles } from 'react-icons/bs';
import { BsTable } from 'react-icons/bs';
import { BsGraphUp } from 'react-icons/bs';
import { BiServer } from 'react-icons/bi';


export function ArtifactIcon({ item ,size}) {
    const theme = useTheme();
    if (item === 'FileDataset' || item === 'File') {
        return <BsFiles color={theme.palette.secondary.contrastText}  size={size}/>;
    }
    if (item === 'TableDataset' || item === 'Table') {
        return <BsTable color={theme.palette.secondary.contrastText} size={size} />;
    }
    if (item  === 'Report') {
        return <BsGraphUp color={theme.palette.secondary.contrastText} size={size}/>;
    }
    if (item  === 'App') {
        return <BiServer color={theme.palette.secondary.contrastText} size={size}/>;
    }
    if (item  === 'EmbeddedApp' || item  === 'Apps') {
        return <BiServer color={theme.palette.secondary.contrastText} size={size} />;
    }
}

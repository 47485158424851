import gql from "graphql-tag";
export const LIST_ALL_USERS = gql`
  query ListUsers {
    listUsers {
      _id
      firstName
      lastName
      email
    }
  }
`;

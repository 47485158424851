import { useQuery } from "@apollo/client";
import React from "react";

import { Container, Divider, List } from "@mui/material";
import CenteredProgress from "./CenteredProgress";

import Crumbs from "./Crumbs";
import RouterListItem from "./RouterListItem";


import ReportsList from "../pages/ReportsList";

import { LIST_EMBEDDEDAPPS } from "../graphql/queryEmbeddedApp";
import EmbeddedAppList from "../pages/EmbeddedAppList";

import errormessage from "./ErrorMessage";

export default function DashboardContainer({ loading, error, currentUser, tenant, availableTenants }) {
  const {
    loading: loadingEmbeddedApp,
    error: errorEmbeddedApp,
    data: dataEmbeddedApp,
  } = useQuery(LIST_EMBEDDEDAPPS);

  if (loading || loadingEmbeddedApp) {
    return <CenteredProgress />;
  } else if (error || errorEmbeddedApp) {
    return errormessage({
      title: 'Permission Needed',
      description: "You don't have access to data please contact support team.",
    });
  }

  var isEmbeddedAppNull = Object.keys(dataEmbeddedApp).every(function (key) {
    return dataEmbeddedApp[key].length === 0;
  });

  if (
    currentUser.listOfAccessedTenants === '' ||
    availableTenants.length === 0
  ) {
    return errormessage({
      title: 'Permission Needed',
      description: "You don't have access to data please contact support team.",
    });
  } else if (availableTenants.length === 1) {
    if (!isEmbeddedAppNull){ 
      return (<EmbeddedAppList tenant={tenant} />);
    }
    else {
      return (<ReportsList tenant={tenant}/>);
    }
  }

  return (
    <Container maxWidth="false">
      <Crumbs parts={[]} />
      <List>
        {availableTenants.map((t, idx) => (
          <div key={t._id}>
            <RouterListItem
              to={`/${t.name}`}
              displayName={t.displayName}
              description={t.description}
              tenantName={t.displayName}
              tenantId={t._id}
              type="Tenant"
            />
            {idx !== availableTenants.length - 1 ? <Divider /> : null}
          </div>
        ))}
      </List>
    </Container>
  );
}

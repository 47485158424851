import gql from "graphql-tag";

export const ARTIFACT_CONNECTION = gql`
    query artifactConnection($tenantId: String, $after: String, $filter: ArtifactFilterInput, $first: Float, $sort: [ArtifactSortInput!]){
        artifactConnection(
            tenantId: $tenantId
            after: $after
            filter: $filter
            first: $first
            sort: $sort
        ) {
            pageInfo {
                startCursor
                totalDocs
            }
            edges {
                cursor
                node {
                    __typename
                    ... on Report {
                        _id
                        name
                        displayName
                    }
                    ... on FileDataset {
                        _id
                        name
                        displayName
                    }
                    ... on TableDataset {
                        _id
                        name
                        displayName
                    }
                    ... on App {
                        _id
                        name
                        displayName
                    }
                    ... on EmbeddedApp{
                        _id
                        name
                        displayName
                    }
                }
            }
        }
    }
`;

export const ARTIFACT_CONNECTION_WITH_DESCRIPTION = gql`
    query artifactConnection($tenantId: String, $after: String, $filter: ArtifactFilterInput, $first: Float, $sort: [ArtifactSortInput!]){
        artifactConnection(
            tenantId: $tenantId
            after: $after
            filter: $filter
            first: $first
            sort: $sort
        ) {
            pageInfo {
                startCursor
                totalDocs
            }
            edges {
                cursor
                node {
                    __typename
                    ... on Report {
                        name
                        displayName
                    }
                    ... on FileDataset {
                        _id
                        name
                        displayName
                        description
                        abilities {
                            canDownload
                            canUpload
                        }
                    }
                    ... on TableDataset {
                        _id
                        name
                        displayName
                        tableName
                        columns {
                            name
                            dataType
                        }
                    }
                    ... on App {
                        name
                        displayName
                    }
                    ... on EmbeddedApp{
                        name
                        displayName
                    }
                }
            }
        }
    }
`;

export const ARTIFACT_CONNECTION_DETAILED = gql`
    query artifactConnection(
        $tenantId: String
        $after: String
        $filter: ArtifactFilterInput
        $first: Float
        $sort: [ArtifactSortInput!]
    ) {
        artifactConnection(
            tenantId: $tenantId
            after: $after
            filter: $filter
            first: $first
            sort: $sort
        ) {
            pageInfo {
                startCursor
                totalDocs
            }
            edges {
                cursor
                node {
                    __typename
                    ... on Report {
                        _id
                        name
                        displayName
                        description
                        pbiName
                        pbiDescription
                        pbiEmbedUrl
                        pbiEmbedToken
                    }
                    ... on FileDataset {
                        _id
                        name
                        displayName
                        description
                        abilities {
                            canDownload
                            canUpload
                        }
                        fileslots {
                            _id
                            fileName
                            name
                            externalPath
                            lastModifiedUser {
                                email
                            }
                            currentVersion {
                                _id
                                name
                                fileName
                                createdAt
                                downloadUrl
                                source
                                status
                                user
                              }
                        }
                    }
                    ... on TableDataset {
                        _id
                        name
                        displayName
                        tableName
                        columns {
                            name
                            dataType
                        }
                    }
                    ... on App {
                        _id
                        name
                        displayName
                    }
                    ... on EmbeddedApp {
                        _id
                        name
                        displayName
                        url
                    }

                }
            }
        }
    }
`;

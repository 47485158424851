import _ from 'lodash';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid, Box, Typography, Modal, Backdrop, Container, Button } from "@mui/material";
import { UPDATE_CONDUIT } from '../graphql/mutationUpdateConduit';
import '../Form.css';
import Select from "react-select";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: '#fff',
    boxShadow: 24,
    padding: '0 !important',
    margin: '0 0 15px 0 !important'
};

export default function UpdateConduit({ tenant, handleNewRow, file, showModal, onClose }) {
    const [formError, setFormError] = useState();
    const [updateConduit] = useMutation(UPDATE_CONDUIT);
    const handleClose = () => {
        onClose();
    };
    let selectedFromArtifact = [];
    let selectedToArtifact = [];

    selectedFromArtifact.push(_.get(file, "fromArtiafact", []));
    selectedToArtifact.push(_.get(file, "toArtifact", []));

    const fromArtifactList = selectedFromArtifact ? selectedFromArtifact.map((a) => {
        return {
            value: a, label: a
        }
    }) : '';

    const toArtifactList = selectedToArtifact ? selectedToArtifact.map((a) => {
        return {
            value: a, label: a
        }
    }) : '';

    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        //Name Validation
        if (!value) {
            setFormError("**cannot be empty")
        }
        else if (typeof value !== "undefined") {
            if (!value.match(/^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$/)) {
                setFormError("**Only letters and numbers are accepted")
            } else {
                setFormError(" ")
            }
        }
    }

    const handlesubmit = async (event) => {
        event.preventDefault();
        try {
            const r = await updateConduit({
                variables: {
                    updateConduitId: file.id,
                    input: {
                        name: event.target[0].value,
                        description: event.target[1].value,
                        dataFactoryName: event.target[2].value,
                        pipelineName: event.target[3].value,

                    },
                    tenantId: tenant._id
                },
            });

            const item = _.get(r, 'data.updateConduit');
            const newConduit =
            {
                id: _.get(item, "_id"),
                name: _.get(item, 'name'),
                fromArtiafact: _.get(item, 'fromArtifact.displayName'),
                toArtifact: _.get(item, 'toArtifact.displayName'),
                LastUpdate: file.LastUpdate,
                Status: file.Status,
                description: _.get(item, 'description'),
                dataFactoryName: _.get(item, 'dataFactoryName'),
                piplineName: _.get(item, 'pipelineName'),
            };

            handleNewRow(newConduit);
            handleClose();
        }
        catch (error) {
            alert(error);
        }

    };

    return (
        <>
            <Modal
                aria-labelledby="modal-title"
                open={showModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style}>
                    <Grid container >
                        <Grid item xs={12} style={{ backgroundColor: '#1f576c' }}>
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                style={{ padding: '10px', color: '#fff', textAlign: 'center' }}
                            >
                                Update Conduit
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Container>
                                <form className="addteam" onSubmit={handlesubmit}>
                                    <div>
                                        <label className="fields">
                                            Name
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="ConduitName"
                                                defaultValue={file.name}
                                                required
                                            />
                                        </label>
                                        {formError &&
                                            <span className='error'>{formError}</span>}
                                    </div>
                                    <div>
                                        <label className="fields">
                                            Description
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="Description"
                                                defaultValue={file.description}
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <label className="fields">
                                            Data Factory Name
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="DataFactoryName"
                                                defaultValue={file.dataFactoryName}
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <label className="fields">
                                            Pipeline Name
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="PipelineName"
                                                defaultValue={file.piplineName}
                                                required
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 10px 0px 5px', fontSize: '14px' }}
                                        >
                                            From Artifact
                                        </Typography>
                                        <Select
                                            defaultValue={fromArtifactList[0]}
                                            options={fromArtifactList}
                                            isDisabled
                                        />

                                    </div>
                                    <div>
                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 25px 0px 5px', fontSize: '14px' }}
                                        >
                                            To Artifact
                                        </Typography>
                                        <Select
                                            defaultValue={toArtifactList[0]}
                                            maxMenuHeight={125}
                                            options={toArtifactList}
                                            isDisabled
                                        />

                                    </div>
                                    <div className="form-buttons">
                                        <Button className="cancel" onClick={onClose}>Cancel</Button>
                                        <input type="submit" value="Save Conduit" data-testid="saveConduit"/>
                                    </div>

                                </form>
                            </Container>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </>
    )

}
import gql from "graphql-tag";

export const UPDATE_TENANT = gql`
  mutation UpdateTenant($input: UpdateTenantInput!, $tenantId: String!) {
    updateTenant(input: $input, tenantId: $tenantId) {
      displayName
      _id
      abilities {
        canCreateTeam
        canDownloadFromFileDatasets
        canReadTeams
        canRemoveTeams
        canUpdateTeams
        canUploadToFileDatasets
      }
      domain
      isValid
      name
      tenantTeams {
        _id
      }
      tenantUsers {
        user {
          _id
        }
      }
    }
  }
`;

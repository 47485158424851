import gql from 'graphql-tag';

export const UPDATE_CONDUIT = gql`
mutation UpdateConduit($updateConduitId: String!, $input: UpdateConduitInput!, $tenantId: String!) {
    updateConduit(id: $updateConduitId, input: $input, tenantId: $tenantId) {
      _id
      name
      description
      dataFactoryName
      pipelineName
      fromArtifact {
        ... on App {
          artifactType
          displayName
        }
        ... on EmbeddedApp {
          artifactType
          displayName
        }
        ... on FileDataset {
          artifactType
          displayName
        }
        ... on Report {
          artifactType
          displayName
        }
        ... on TableDataset {
          artifactType
          displayName
        }
      }
      toArtifact {
        ... on App {
          artifactType
          displayName
        }
        ... on EmbeddedApp {
          artifactType
          displayName
        }
        ... on FileDataset {
          artifactType
          displayName
        }
        ... on Report {
          artifactType
          displayName
        }
        ... on TableDataset {
          artifactType
          displayName
        }
      }
      lastConduitRun {
        status
        finishedAt
        successful
      }
    }
  }
`;
import React, { useState, useLayoutEffect } from 'react'
import { FormControl, Grid, Box, Typography, Modal, Container, Button, Select, InputLabel, MenuItem } from "@mui/material";
import '../Form.css'
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../graphql/mutationUpdateUser';
import _ from 'lodash';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  bgcolor: '#fff',
  boxShadow: 24,
  padding: '0 !important',
  margin: '0 0 15px 0 !important'
};

export default function UpdateUser({ onClose, user, tenant, updateusers, showModal }) {
  const [formError, setFormError] = useState()
  const [role, setRole] = useState()
  const [updateUser] = useMutation(UPDATE_USER);

  useLayoutEffect(() => setRole(user.role), [user])

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const r = await updateUser({
        variables: {
          input: {
            _id: user.id,
            firstName: event.target[0].value,
            lastName: event.target[1].value,
            email: event.target[2].value,
            role: role ? role : user.role
          },
          tenantId: tenant._id
        },
      });
      const editedUser = _.get(r, 'data.updateUser');
      editedUser.id = editedUser._id
      editedUser.role = role;
      updateusers(editedUser)
      onClose()
    } catch (err) {
      setFormError(err)
    }
  }

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        open={showModal}
        onClose={() => onClose()}
        closeAfterTransition
      >
        <Box sx={style}>
          <Grid container>
            <Grid item xs={12} style={{ backgroundColor: "#1f576c" }}>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                style={{ padding: "10px", color: "#fff", textAlign: "center" }}
              >
                Update User
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Container>
                <form className="editteam" onSubmit={handleSubmit}>
                  {formError && <span className="error">{formError}</span>}
                  <div>
                    <label className="fields">
                      First Name:
                      <input
                        type="text"
                        name="UserFirstName"
                        required
                        defaultValue={user.firstName}
                      />
                    </label>
                  </div>
                  <div>
                    <label className="fields">
                      Last Name:
                      <input
                        type="text"
                        name="UserLastName"
                        required
                        defaultValue={user.lastName}
                      />
                    </label>
                  </div>
                  <div>
                    <label className="fields">
                      Email:
                      <input
                        type="text"
                        name="UserEmail"
                        required
                        defaultValue={user.email}
                      />
                    </label>
                  </div>
                  <div style={{ marginTop: 40 }}>
                    <FormControl fullWidth>
                      <InputLabel>Role</InputLabel>
                      <Select
                        value={role}
                        label="Role"
                        defaultValue={user.role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <MenuItem value="OWNER">Owner</MenuItem>
                        <MenuItem value="READER">Reader</MenuItem>
                        <MenuItem value="MEMBER">Member</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-buttons">
                    <Button className="cancel" onClick={onClose}>
                      Cancel
                    </Button>
                    {/* <Button className="saveteam" onClick={handleSubmit}>Save Team</Button> */}
                    <input
                      type="submit"
                      value="Save User"
                      data-testid="saveTeam"
                    />
                  </div>
                </form>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

import React, { useState } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { ADD_TEAM } from '../graphql/mutationAddTeam';
import _ from 'lodash';
import { LIST_USERS_TENANT } from '../graphql/queryListUsersOfTenant';
import CenteredProgress from '../components/CenteredProgress';
import { Grid, Box, Typography, Modal, Backdrop, Container, Button } from "@mui/material";
import '../Form.css'
import Select, { components } from "react-select";
import { ADD_USERS_TO_TEAM } from '../graphql/mutationAddUserstoTeam';
import { ARTIFACT_CONNECTION } from '../graphql/queryArtifactConnection';
import { ADD_USERS_PERMISSION,UPDATE_REPORTS_PERMISSION} from '../graphql/mutationAddUserPermissions';

export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    bgcolor: '#fff',
    boxShadow: 24,
    padding: '0 !important',
    margin: '0 0 15px 0 !important'
};
export const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
    const onChange = () => console.log;

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} data-testid={children.id} onChange={onChange} />
            {children}
        </components.Option>
    );
};

export default function AddTeam({ tenant, teamlist, updateteams, open, onClose }) {

    const [formError, setFormError] = useState();
    const [addTeam] = useMutation(ADD_TEAM);
    const [addTeamUsers] = useMutation(ADD_USERS_TO_TEAM);
    const [selectedusers, setSelectedUsers] = useState([]);
    const [selectedapps, setSelectedApps] = useState([]);
    const [selectedeports, setSelectedReports] = useState([]);
    
    const [addTeamPermission] = useMutation(ADD_USERS_PERMISSION);
    const [addTeamReportPermission] = useMutation(UPDATE_REPORTS_PERMISSION);
    let newteamitem = []
    const {
        loading: loadingListUsers,
        error: errorListUsers,
        data: dataListUsers,
    } = useQuery(LIST_USERS_TENANT, {
        variables: {
            input: {
                _id: tenant._id,
            },
        },
    });
    const usersData = dataListUsers ? dataListUsers.findOneTenant : '';
    const users = usersData ? usersData.tenantUsers.map((c) => {
        return {
            id: _.get(c, 'user._id'),
            firstName: _.get(c, 'user.firstName'),
            lastName: _.get(c, 'user.lastName'),
            email: _.get(c, 'user.email'),
        };
    }) : '';
    const userList = users ? users.map((u) => {
        return {
            value: u.id, label: u.firstName + " " + u.lastName
        }

    }) : '';

    const { loading: allEmbeddedApploading,
        error: allEmbeddedAppError,
        data: allEmbeddedAppData, } = useQuery(ARTIFACT_CONNECTION, {
            variables: {
                tenantId: tenant ? tenant._id : '',
                filter: {
                    types: ["Report","EmbeddedApp"],
                },
            }
        });
    const edges = _.get(allEmbeddedAppData, "artifactConnection.edges", []);
    const items = edges ? edges.map((e) => e.node) : '';
    const embeddedApps = items ? items.filter(e => e.__typename==='EmbeddedApp') : '';
    const reports= items ? items.filter(e=> e.__typename==='Report'):'';
    const embeddedAppList = embeddedApps ? embeddedApps.map((a) => {
        return {
            value: a._id, label: a.name
        }
    }) : '';

    const ReportsList = reports ? reports.map((a) => {
        return {
            value: a._id, label: a.name
        }
    }) : '';

    const handleChange = (event) => {
        event.preventDefault();
        const { value } = event.target;
        //Name Validation
        if (!value) {
            setFormError("**Team name cannot be empty")
        }
        else if (typeof value !== "undefined") {
            if (!value.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/)) {
                setFormError("**Only letters and numbers are accepted")
            } else {
                setFormError(" ")
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let newTeam;
        try {
            const team = await addTeam({
                variables: {
                    input: {
                        name: event.target[0].value,
                        displayName: event.target[0].value.replace(/\s/g, "_"),
                        abilities: {
                            canDownloadFromFileDatasets: event.target[2].checked,
                            canUploadToFileDatasets: event.target[1].checked
                        }
                    },
                    tenantId: tenant._id
                },
            });
             newTeam = _.get(team, 'data.createTeam');

            if (selectedapps.length > 0) {
                const teampermission = await addTeamPermission({
                    variables: {
                        embeddedAppId: selectedapps,
                        input: {
                            canRead: true
                        },
                        teamId: newTeam._id,
                        tenantId: tenant._id
                    },

                })
                newTeam = _.get(teampermission, 'data.updateManyEmbeddedAppPermissions');
               
                setSelectedApps([])
            }
            if (selectedeports.length > 0) {
                const teamReportPermission = await addTeamReportPermission({
                    variables: {
                        reportId: selectedeports,
                        input: {
                            canRead: true
                        },
                        teamId: newTeam._id,
                        tenantId: tenant._id
                    },

                })
                newTeam = _.get(teamReportPermission, 'data.updateManyReportsPermissions');
            
                setSelectedReports([])
            }

            let newmemebers;
            if (selectedusers.length > 0) {
                const teamusers = await addTeamUsers({
                    variables: {
                        teamId: newTeam._id,
                        userId: selectedusers
                    },

                })
                newmemebers = _.get(teamusers, 'data.addManyUsersToTeam');
                setSelectedUsers([])
            }

            newteamitem.push(newmemebers ? newmemebers : newTeam)
           
            const teams = newteamitem.map(c => {
                return {
                    id: _.get(c, "_id"),
                    name: _.get(c, "displayName"),
                    members: _.get(c, "members"),
                    permissions: _.get(c, "teampermissions"),

                };
            });

            updateteams(teams[0])
            newteamitem.pop();
            onClose();

        }
        catch (error) {
            alert(error);
        }
    };

    if (loadingListUsers || errorListUsers || allEmbeddedApploading || allEmbeddedAppError) {
        return <CenteredProgress />;
    }

    return (
        <>
            {/* <Button startIcon={<AddCircleOutlineIcon />} onClick={handleOpen} style={{ backgroundColor: '#1f576c', color: '#fff', float: 'right' }} component="span">Add Team</Button> */}
            <Modal
                aria-labelledby="modal-title"
                open={open}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={style}>
                    <Grid container >
                        <Grid item xs={12} style={{ backgroundColor: '#1f576c' }}>
                            <Typography
                                id="modal-title"
                                variant="h6"
                                component="h2"
                                style={{ padding: '10px', color: '#fff', textAlign: 'center' }}
                            >
                                Create New Team
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Container>
                                <form className="addteam" onSubmit={handleSubmit}>
                                    <div>
                                        <label className="fields">
                                            Team Name:
                                            <input
                                                onChange={handleChange}
                                                type="text"
                                                name="TeamName"
                                                required
                                            />
                                        </label>
                                        {formError &&
                                            <span className='error'>{formError}</span>}
                                    </div>
                                    <div>

                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please define if team member can upload/download files
                                        </Typography>
                                        <input
                                            type="checkbox"
                                            value="canupload"
                                        />
                                        <label className="teamability" htmlFor="canupload">Upload</label>
                                        <input
                                            type="checkbox"
                                            value="candownload"
                                        />
                                        <label className="teamability" htmlFor="candownload">Download</label>
                                    </div>
                                    <div>
                                        {embeddedAppList.length > 0 ? <><Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please select which apps this team could read
                                        </Typography>
                                            <Select
                                                defaultValue={[]}
                                                isMulti
                                                onChange={(options) => {
                                                    if (Array.isArray(options)) {
                                                        setSelectedApps(options.map((opt) => opt.value));
                                                    }
                                                }}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={embeddedAppList}
                                                id="addTeamApps"
                                                components={{
                                                    Option: InputOption
                                                }}
                                            /> </> : ''}

                                    </div>
                                    <div>
                                    {ReportsList.length > 0 ? <><Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please select which reports this team could read
                                        </Typography>
                                            <Select
                                                defaultValue={[]}
                                                isMulti
                                                onChange={(options) => {
                                                    if (Array.isArray(options)) {
                                                        setSelectedReports(options.map((opt) => opt.value));
                                                    }
                                                }}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                options={ReportsList}
                                                id="addTeamReports"
                                                components={{
                                                    Option: InputOption
                                                }}
                                            /> </> : ''}
                                    </div>
                                    <div>
                                        <Typography
                                            id="modal-title"
                                            variant="h6"
                                            component="h4"
                                            style={{ padding: ' 20px 0px', fontSize: '16px' }}
                                        >
                                            Please select team members
                                        </Typography>
                                        <Select
                                            defaultValue={[]}
                                            isMulti
                                            maxMenuHeight={125}
                                            onChange={(options) => {
                                                if (Array.isArray(options)) {
                                                    setSelectedUsers(options.map((opt) => opt.value));
                                                }
                                            }}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            options={userList}
                                            id="addTeamMember"
                                            components={{
                                                Option: InputOption
                                            }}
                                        />

                                    </div>
                                    <div className="form-buttons">
                                        <Button className="cancel" onClick={onClose}>Cancel</Button>
                                        {/* <Button className="saveteam" onClick={handleSubmit}>Save Team</Button> */}
                                        <input type="submit" value="Save Team" data-testid="saveTeam" />
                                    </div>

                                </form>
                            </Container>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
        </>
    );

}
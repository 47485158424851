import Alert from '@mui/material/Alert';
import { SecureRoute } from '@okta/okta-react';
import { Switch } from 'react-router-dom';
import CenteredProgress from '../components/CenteredProgress';
import Home from '../components/Home';
import Tenant from '../components/Tenant';
import Conduits from './Conduits';
import Dataset from './Dataset';
import Datasets from './DatasetsList';
import EmbeddedApp from './EmbeddedApp';
import EmbeddedAppList from './EmbeddedAppList';
import ReportsPage from './EmbeddedReport';
import ListOfUsersTable from './ListAllUsers';
import ReportsList from './ReportsList.js';
import ListofTeams from "./TenantTeams";
import ReportHealthMonitor from "./ReportHealthMonitor";
import ConduitList from './ConduitList';
import Tenants from '../components/TenantsConfig';
// import AnalyticsChart from '../components/AnalyticsChart.js';
import ContactUs from '../components/ContactUs.js';

const tenantPages = [
  {
    name: 'EmbeddedApp',
    path: '/:tenantName/a/:EmbeddedAppId',
    Component: EmbeddedApp,
  },
  {
    name: 'EmbeddedAppList',
    path: '/:tenantName/a/',
    Component: EmbeddedAppList,
  },
  { name: 'Dataset', path: '/:tenantName/d/:dname', Component: Dataset },
  { name: 'Datasets', path: '/:tenantName/d', Component: Datasets },
  { name: 'Report', path: '/:tenantName/r/:reportId', Component: ReportsPage },
  { name: 'Reports', path: '/:tenantName/r', Component: ReportsList },
  { name: 'Users Info', path: '/:tenantName/u', Component: ListOfUsersTable },
  { name: 'Team Info', path: '/:tenantName/t', Component: ListofTeams },
  { name: 'Health', path: '/:tenantName/h', Component: ReportHealthMonitor },
  { name: 'Conduits', path: '/:tenantName/c', Component: Conduits },
  { name: 'ConduitsList', path: '/:tenantName/cl', Component: ConduitList },
  // { name: 'Activity', path: '/:tenantName/ac', Component: AnalyticsChart },
  { name: 'Contact Us', path: '/:tenantName/cu', Component: ContactUs},
  { name: 'Tenant', path: '/:tenantName', Component: Tenant },
];

const TenantRouter = ({
  loading,
  error,
  tenant,
  currentUser,
  availableTenants,
}) => {
  if (loading || !tenant) {
    return <CenteredProgress />;
  }
  if (error) {
    return <Alert severity="error">Oops! An Error occured.</Alert>;
  }
  return (
    <Switch>
      {tenantPages.map((page, index) => (
        <SecureRoute path={page.path} key={index}>
          <page.Component
            {...{ loading, error, tenant, currentUser, availableTenants }}
          />
        </SecureRoute>
      ))}
    </Switch>
  );
};

const pages = [
  { name: 'configs', path: '/configs', Component: Tenants },
  { name: 'Tenant', path: '/:tenantName', Component: TenantRouter },
  { name: 'Home', path: '/', Component: Home },
];

const MainRouter = ({
  loading,
  error,
  tenant,
  currentUser,
  availableTenants,
}) => {
  return (
    <Switch>
      {pages.map((page, index) => (
        <SecureRoute path={page.path} key={index}>
          <page.Component
            {...{ loading, error, tenant, currentUser, availableTenants }}
          />
        </SecureRoute>
      ))}
    </Switch>
  );
};

export default MainRouter;

import React, { useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import '../Form.css';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@mui/material';
// import { Delete, Edit } from '@mui/icons-material';
import { CREATE_USER } from '../graphql/mutationCreateUser';
// import FormHelperText from '@mui/material/FormHelperText';


export const CreateNewUserModal = ({ open, columns, onClose, handleCreateNewRow, tenantId }) => {
    // const [role, setRole] = React.useState('');
    const [createUser] = useMutation(CREATE_USER);

    // const handleChange = (event) => {
    //     setRole(event.target.value);
    // };


    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            if (column.accessorKey !== "id")
                acc[column.accessorKey ?? ''] = '';

            return acc;
        }, {}),
    );


    const handlesubmit = async (e) => {
        //put your validation logic here
        // handleCreateNewRow(values);
        e.preventDefault()
        try {
            const r = await createUser({
                variables: {
                    input: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email
                    },
                    role: values.role,
                    tenantId: tenantId
                },
            });

            const newUser = _.get(r, 'data.createUser');
            newUser.id = _.get(r, 'data.createUser._id');
            const newUserWithRole = Object.assign({}, newUser, { role: values.role });
            handleCreateNewRow(newUserWithRole)
        }
        catch (error) {
            alert(error);
        }
        onClose();
    };

    return (
        <Dialog open={open} >
            <DialogTitle textAlign="center"  style={{ padding: '10px', color: '#fff', backgroundColor: '#1f576c', marginBottom: '15px' }}>Create New User</DialogTitle>
            <DialogContent >
                <form onSubmit={handlesubmit}>
                    <Stack
                        sx={{
                            p: 1,
                            width: '100%',
                            minWidth: { xs: '300px', mt: '400px', pt: '400px' },
                            gap: '1.5rem',

                        }}
                    >
                        {columns.map((column) => (
                            column.header !== "ID" && column.header !== "Role" ?
                                <TextField
                                    key={column.accessorKey}
                                    label={column.header}
                                    name={column.accessorKey}
                                    onChange={(e) =>
                                        setValues({ ...values, [e.target.name]: e.target.value })
                                    }
                                />
                                : null
                        ))}

                        <FormControl>
                            <FormLabel id="role-row-radio-buttons-group-label">Role</FormLabel>
                            <RadioGroup
                                sx={{
                                    borderRadius: 2,
                                    p: 2,
                                    minWidth: 200,
                                }}
                                row
                                aria-labelledby="role-row-radio-buttons-group-label"
                                name="role"
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            >
                                <FormControlLabel value="OWNER" control={<Radio size="small" />} label="Owner" className="userrole"/>
                                <FormControlLabel value="READER" control={<Radio size="small" />} label="Reader" className="userrole"/>
                                <FormControlLabel value="MEMBER" control={<Radio size="small" />} label="Member" className="userrole"/>
                            </RadioGroup>
                        </FormControl>
                    </Stack>

                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }} className="form-buttons">
                <Button onClick={onClose} className="cancel">Cancel</Button>
                <Button color="success" onClick={handlesubmit} className="saveuser">
                    Create User
                </Button>
            </DialogActions>
        </Dialog>
    );
};
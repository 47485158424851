import gql from "graphql-tag";
export const LIST_CONDUIT = gql`
query ListConduits($tenantId: String, $input: ListConduitInput) {
    listConduits(tenantId: $tenantId, input: $input) {
      _id
      name
      dataFactoryName
      description
      pipelineName
      lastConduitRun {
        finishedAt
        successful
        status
      }
      fromArtifact {
        ... on Report {
          artifactType
          displayName
        }
        ... on TableDataset {
          artifactType
          displayName
        }
        ... on FileDataset {
          artifactType
          displayName
        }
        ... on EmbeddedApp {
          artifactType
          displayName
        }
        ... on App {
          artifactType
          displayName
        }
      }
      toArtifact {
        ... on App {
          artifactType
          displayName
        }
        ... on EmbeddedApp {
          artifactType
          displayName
        }
        ... on FileDataset {
          artifactType
          displayName
        }
        ... on Report {
          artifactType
          displayName
        }
        ... on TableDataset {
          artifactType
          displayName
        }
      }
    }
  }
`;
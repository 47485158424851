import React from 'react';
import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import '../assets/pbi.css';


function PbiEmbed(props) {
    const reportConfig = {
        type: 'report',
        embedUrl: props.embedUrl,
        tokenType: models.TokenType.Embed,
        accessToken: props.embedToken,
        settings: props.settings,
    };

    return (
        <div>
            <PowerBIEmbed
                embedConfig={reportConfig}
                cssClassName={"report-style-class"}
            />
        </div>
    );
}

export default PbiEmbed;

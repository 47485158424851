import gql from 'graphql-tag';

export const ADD_FILEVERSION = gql`
  mutation CreateFileVersion($tenantId: String!
    $fileslot: String!
    $name: String!
    $fileName: String!
    $status: String!
    $source: String!
    $user: String!) {
    createFileVersion(tenantId: $tenantId, input: {fileslot:$fileslot ,name: $name, fileName: $fileName , status: $status , source: $source , user : $user }) {
      _id
      name
      fileName
      source
      user
      status
      createdAt
      uploadUrl
      downloadUrl
    }
  }  

`;


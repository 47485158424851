import {  Typography} from '@mui/material';
import { ArtifactIcon } from './ArtifactIcon';


export const ArtifactType = ({ artifactName,artifactType }) => {
    return (  
        // <Chip
        //     icon={<ArtifactIcon item={artifactType} size='16px' />}
        //     sx={{ p: '5px' }}
        //     label={artifactName}
        //     variant="outlined"
        //     size="small"
        //     style={{border:'none',fontSize:'14px', display:'flex', overflow:'hidden',width:'100%', textOverflow:'ellipsis', maxWidth:'170px' }}
        // />
        <div style={{display:'flex'}}>
        <ArtifactIcon item={artifactType} size='16px' style={{width:'30px'}} />
        <Typography style={{ fontSize: '14px', overflow: 'hidden', textOverflow: 'ellipsis' , paddingLeft:'7px',width:'200px',  }}>{artifactName}</Typography>
        </div>
    );
};

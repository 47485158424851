import React from "react";
import CenteredProgress from "../components/CenteredProgress";
import { useOktaAuth } from "@okta/okta-react";
import Welcome from "./Welcome";
import GraphqlContainer from "../layout/GraphqlContainer";

const AuthComponent = () => {
  const { authState } = useOktaAuth();
  
  if (!authState) {
    return <CenteredProgress />;
  }

  if (!authState.isAuthenticated) {
    console.count("Not authenticated, redirecting to /welcome");
    return <Welcome />;
  }

  return <GraphqlContainer />;
};

export default AuthComponent;

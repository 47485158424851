import { Typography, Alert, Button, Radio, FormControl, FormLabel, RadioGroup, FormControlLabel, Box, Modal, Grid, Container } from '@mui/material'
import Select from 'react-select'
import React, { useState } from 'react'
import { InputOption, style } from './AddTeam'
import CenteredProgress from '../components/CenteredProgress'
import { LIST_ALL_USERS } from '../graphql/queryListUsers'
import { useMutation, useQuery } from '@apollo/client'
import { ADD_MANY_USERS_TO_TENANT } from '../graphql/mutationAddManyUsersToTenant'
import _ from 'lodash'

export default function AddExistingUserModal({ open, columns, onClose, updateTableData, tenantId, tableData }) {
  const [selectedUsers, setSelectedUsers] = useState([])
  const [values, setValues] = useState()
  const { loading: loadingListUsers, data: dataListUsers, error: errorListUsers } = useQuery(LIST_ALL_USERS)
  const [addManyUsersToTenant] = useMutation(ADD_MANY_USERS_TO_TENANT)


  const users = dataListUsers ? dataListUsers.listUsers.map((c) => {
    return {
      id: c._id,
      firstName: c.firstName,
      lastName: c.lastName,
      email: c.email,
    };
  }) : '';

  const tableDataWithoutRole = tableData.map(
    ({ role, ...otherDetails }) => otherDetails
  );

  const newUsers =
    users &&
    users.filter(
      (item) => !tableDataWithoutRole.find((data) => _.isEqual(item, data))
    );

  const usersList = users ? newUsers.map((u) => {
    return {
      value: u.id,
      label: u.firstName + " " + u.lastName,
      id: u.id,
      email: u.email,
      firstName: u.firstName,
      lastName: u.lastName,
    };

  }) : '';

  const handleSubmit = async () => {
    let userIdList = selectedUsers.map(({ id }) => id)
    const r = await addManyUsersToTenant({
      variables: {
        role: values?.role,
        tenantId,
        userIdList,
      },
    })
    const tenantUsers = _.get(r, "data.addManyUsersToTenant.tenantUsers");
    const newTableData = tenantUsers.map((tu) => {
      return {
        id: _.get(tu, "user._id"),
        firstName: _.get(tu, "user.firstName"),
        lastName: _.get(tu, "user.lastName"),
        email: _.get(tu, "user.email"),
        role: _.get(tu, "role")
      };
    });
    updateTableData(newTableData)
    onClose();
  };


  if (loadingListUsers) {
    <CenteredProgress />
  } else if (errorListUsers) {
    <Alert severity="error">Oops! An error occured during the process!</Alert>;
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs={12} style={{ backgroundColor: '#1f576c' }}>
            <Typography
              id="modal-title"
              variant='h6'
              component="h2"
              style={{ padding: '10px', color: '#fff', textAlign: 'center' }}
            >
              Add Existing Users
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Container>
              <form className="addteam" onSubmit={handleSubmit}>
                <div>
                  <Typography
                    id="modal-title"
                    variant="h6"
                    component="h4"
                    style={{ padding: " 20px 0px", fontSize: "16px" }}
                  >
                    Please Select User
                  </Typography>
                  <Select
                    defaultValue={[]}
                    isMulti
                    maxMenuHeight={128}
                    onChange={(options) => {
                      if (Array.isArray(options)) {
                        setSelectedUsers(options.map((o) => o));
                      }
                    }}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={usersList}
                    components={{
                      Option: InputOption,
                    }}
                  />
                </div>
                <div>
                  <FormControl style={{ marginTop: 30 }}>
                    <FormLabel id="role-row-radio-buttons-group-label">Role</FormLabel>
                    <RadioGroup
                      sx={{
                        borderRadius: 2,
                        p: 2,
                        minWidth: 200,
                      }}
                      row
                      aria-labelledby="role-row-radio-buttons-group-label"
                      name="role"
                      onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="OWNER"
                        control={<Radio size="small" />}
                        label="Owner"
                        className="userrole"
                      />
                      <FormControlLabel
                        value="READER"
                        control={<Radio size="small" />}
                        label="Reader"
                        className="userrole"
                      />
                      <FormControlLabel
                        value="MEMBER"
                        control={<Radio size="small" />}
                        label="Member"
                        className="userrole"
                      />
                    </RadioGroup>

                  </FormControl>
                </div>
                <div className="form-buttons">
                  <Button className="cancel" onClick={onClose}>Cancel</Button>
                  <Button className="saveuser" onClick={handleSubmit}>Save User</Button>
                </div>
              </form>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

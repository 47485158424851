import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { Container, Grid, Button, MenuItem, Box, Tooltip, IconButton } from '@mui/material';
import Crumbs from '../components/Crumbs';
import { Edit, Delete } from '@mui/icons-material'

import MaterialReactTable from 'material-react-table';
import Alert from '@mui/material/Alert';
import CenteredProgress from '../components/CenteredProgress';
import { LIST_USERS_TENANT } from '../graphql/queryListUsersOfTenant';
import { CreateNewUserModal } from './CreateUser';
import { REMOVE_USER_FROM_TENANT } from '../graphql/mutationRemoveUserFromTenant.js';
import UpdateUser from './UpdateUser';
import AddExistingUserModal from './AddExistingUser';


export default function ListAllUsers({ tenant, loading }) {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState()
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [removeUserFromTenant] = useMutation(REMOVE_USER_FROM_TENANT);


  const Roles = ['OWNER', 'READER', 'MEMBER'];
  const columns = [
    {
      accessorKey: 'firstName',
      header: 'First Name',
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'role',
      header: 'Role',
      muiTableBodyCellEditTextFieldProps: {
        select: true,
        children: Roles.map((role) => (
          [
            <MenuItem key={role} value={role} style={{ paddingLeft: '8px', textAlign: 'center' }}>
              <div style={{ paddingLeft: '8px' }}>{role}</div>
            </MenuItem>,
          ]
        )),
      },
    },
    {
      accessorKey: 'id',
      header: 'ID',
      enableHiding: true,
      enableEditing: false,
    },
  ];

  const {
    loading: loadingListUsers,
    error: errorListUsers,
    data: dataListUsers,
  } = useQuery(LIST_USERS_TENANT, {
    variables: {
      input: {
        _id: tenant._id,
      },
    },
    skip: !tenant,
  });

  useEffect(() => {
    if (!tableData.length && dataListUsers) {
      const UsersList = _.get(dataListUsers.findOneTenant, "tenantUsers");
      const users = UsersList.map((c) => {
        return {
          id: _.get(c, 'user._id'),
          firstName: _.get(c, 'user.firstName'),
          lastName: _.get(c, 'user.lastName'),
          email: _.get(c, 'user.email'),
          role: _.get(c, 'role'),
        };
      });
      setTableData(users);
    }
  }, [dataListUsers, tableData.length]);

  if (loading || loadingListUsers) {
    return <CenteredProgress />;
  } else if (errorListUsers) {
    return <Alert severity="error">Oops! An error occurred during the process!</Alert>;
  }
  const handleCreateNewRow = (values) => {
    setTableData([values, ...tableData]);
  };

  const updateTableData = (values) => {
    setTableData(values);
  };

  const handleDeleteRow = async (row) => {
    if (!window.confirm(`Are you sure you want to delete ${row.getValue("firstName")}`))
      return;
    try {
      const { data } = await removeUserFromTenant({
        variables: {
          tenantId: tenant._id,
          userId: row.getValue("id")
        },
      });
      const updatedTenantUsers = _.get(data, "removeUserFromTenant.tenantUsers", []);
      const newTableData = updatedTenantUsers.map((tu) => {
        return {
          id: _.get(tu, "user._id"),
          firstName: _.get(tu, "user.firstName"),
          lastName: _.get(tu, "user.lastName"),
          email: _.get(tu, "user.email"),
          role: _.get(tu, "role"),
        };
      });
      setTableData(newTableData);
    } catch (err) {
      console.error(err);
    }
  };


  const updateusers = (newdata) => {
    const index = tableData.findIndex((object) => {
      return object.id === newdata._id;
    });
    if (index >= 0) {
      tableData.splice(index, 1);

      setTableData([newdata, ...tableData]);
    } else {
      setTableData([newdata, ...tableData]);
    }
  };


  return (
    <Container maxWidth={false} sx={{ width: "100%" }}>
      <Grid container spacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Crumbs
            parts={[
              {
                displayName: tenant.displayName,
                path: `/${tenant.name}`,
              },
              {
                displayName: "users",
                path: `/u`,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {currentUser && (
            <UpdateUser onClose={() => setShowModal(false)} user={currentUser} tenant={tenant} updateusers={updateusers} showModal={showModal} />
          )}

        </Grid>
        <Grid item xs={12}>
          <MaterialReactTable
            initialState={{ columnVisibility: { id: false } }}
            enableDensityToggle={false}
            enableColumnFilterModes={false}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableRowActions
            data={tableData}
            columns={columns}
            onDeleteRow={handleDeleteRow}
            editingMode="modal"
            positionActionsColumn="last"
            enableEditing
            renderRowActions={({ row }) => (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton onClick={() => { setShowModal(!showModal); setCurrentUser(row.original) }}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            )} renderTopToolbarCustomActions={() => (

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => setCreateModalOpen(true)}
                  style={{
                    backgroundColor: "#1f576c",
                    color: "#fff",
                  }}
                  component="span"
                >
                  Create New User
                </Button>
                <Button
                  onClick={() => setAddModalOpen(true)}
                  style={{
                    backgroundColor: "#1f576c",
                    marginLeft: 10,
                    color: "#fff",
                  }}
                  component="span"
                >
                  Add User
                </Button>
              </div>
            )}
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#f5f5f5",
                color: "#5e5d5d",
              },
            }}
          />
          <CreateNewUserModal
            columns={columns}
            open={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
            handleCreateNewRow={handleCreateNewRow}
            tenantId={tenant._id}
          />
          <AddExistingUserModal
            columns={columns}
            open={addModalOpen}
            onClose={() => setAddModalOpen(false)}
            updateTableData={updateTableData}
            tenantId={tenant._id}
            tableData={tableData}
          />
        </Grid>
      </Grid>
    </Container>
  );
}            
import { useQuery } from "@apollo/client";
import { Divider, List } from "@mui/material";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import _ from "lodash";
import * as React from "react";
import { ArtifactChip } from "../components/ArtifactChip";
import CenteredProgress from "../components/CenteredProgress";
import Crumbs from "../components/Crumbs";
import NotAvailableMessage from "../components/NotAvailableMessage";
import RouterListItem from "../components/RouterListItem";
import { ARTIFACT_CONNECTION } from "../graphql/queryArtifactConnection";

function EmbeddedAppList({ tenant }) {
  const {
    loading: allEmbeddedApploading,
    error: allEmbeddedAppError,
    data: allEmbeddedAppData,
  } = useQuery(ARTIFACT_CONNECTION, {
    variables: {
      tenantId: tenant ? tenant._id : "",
      filter: {
        types: ["EmbeddedApp"],
      },
    },
  });

  if (allEmbeddedApploading || !tenant) {
    return <CenteredProgress />;
  } else if (allEmbeddedAppError) {
    <Alert severity="error">Oops! An error occured during the process!</Alert>;
  }

  const edges = _.get(allEmbeddedAppData, "artifactConnection.edges", []);
  const items = edges.map((e) => e.node);
  return (
    <Container maxWidth="false">
      <Crumbs
        parts={[
          {
            displayName: tenant.displayName,
            path: `/${tenant.name}`,
          },
          {
            displayName: 'apps',
            path: `/${tenant.name}/a`,
          },
        ]}
      />
      {items.length === 0 ? (
        <NotAvailableMessage item={'Apps'} />
      ) : (
        <List>
          {items.map((a, idx) => (
            <div key={a._id}>
              <RouterListItem
                to={`/${tenant.name}/a/${a.name}`}
                displayName={a.displayName}
                description={a.description}
                icon={<ArtifactChip artifact={a} />}
              />
              {idx !== items.length - 1 ? <Divider /> : null}
            </div>
          ))}
        </List>
      )}
    </Container>
  );
}

export default function DashboardContainer({loading, error, tenant}) {

  if (loading) {
    return <CenteredProgress />;
  } else if (error) {
    <Alert severity="error">
      Oops! An error occured during the process of loading App's List!
    </Alert>;
  }
  return <EmbeddedAppList tenant={tenant} />;
}

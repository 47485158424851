import gql from "graphql-tag";

export const CURRENTUSER_ROLE = gql`
query FindCurrentUserInTenant($tenantId: String!) {
    findCurrentUserInTenant(tenantId: $tenantId) {
      role
      user {
        _id
        email
        firstName
        lastName
      }
    }
  }
`;
import gql from "graphql-tag";
export const LIST_TEAMS_TENANT = gql`
query ListTeams($tenantId: String!) {
  listTeams(tenantId: $tenantId) {
    _id
    abilities {
      canUploadToFileDatasets
      canDownloadFromFileDatasets
    }
    displayName
    members {
      _id
      email
      firstName
      lastName
    }
    name
    teampermissions
  }
}
`;

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import React from 'react';
import { useParams } from 'react-router';
import CenteredProgress from '../components/CenteredProgress';

import { Container } from '@mui/material';
import _ from 'lodash';
import { ArtifactHeaderCard } from '../components/ArtifactHeaderCard';
import { ArtifactHealthTable } from '../components/ArtifactHealthTable';
import PbiEmbed from '../components/PbiEmbed';
import { ARTIFACT_CONNECTION_DETAILED } from '../graphql/queryArtifactConnection';

const Report = ({ tenant }) => {
  const params = useParams();
  const currentReportId = params.reportId;
  return <HealthMonitor tenant={tenant} reportId={currentReportId} />;
};

const HealthMonitor = ({ tenant, reportId }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    loading: itemsLoading,
    error: itemsError,
    data: itemsData,
  } = useQuery(ARTIFACT_CONNECTION_DETAILED, {
    variables: {
      tenantId: tenant ? tenant._id : '',
      filter: {
        name: reportId,
      },
    },
  });

  if (itemsLoading || itemsError) {
    return <CenteredProgress />;
  }

  let server_url = process.env.REACT_APP_API_URL;
  console.log(server_url);

  if (process.env.NODE_ENV === 'production') {
    server_url = '';
  }

  const report = _.get(itemsData, 'artifactConnection.edges.0.node', null);
  if (!report) {
    return <CenteredProgress />;
  }
  return (
    <Container maxWidth="false" sx={{ width: '100%' }}>
      <ArtifactHeaderCard
        breadcrumbs={[
          {
            displayName: tenant.displayName,
            path: `/${tenant.name}`,
          },
          {
            displayName: 'reports',
            path: `/${tenant.name}/r`,
          },
          {
            displayName: report.displayName || report.pbiName,
            path: `/${tenant.name}/r/${report.name}`,
          },
        ]}
        artifact={report}
        tabs={['Report', 'Health']}
        selectedIndex={value}
        onSelectionChange={handleChange}
      />

      <ReportTabPanel value={value} index={0}>
        <Box>
          <PbiEmbed
            embedUrl={report.pbiEmbedUrl}
            embedToken={report.pbiEmbedToken}
          />
        </Box>
      </ReportTabPanel>

      <ReportTabPanel value={value} index={1}>
        <ArtifactHealthTable artifact={report}></ArtifactHealthTable>
      </ReportTabPanel>
    </Container>
  );
};

function ReportTabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default Report;
